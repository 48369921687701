import AppFlowActions from '../../constants';

/**
 *
 * @param {*} data
 */
export const getAllBookingRequest = () => ({ type: AppFlowActions.GET_ALL_BOOKING_REQUEST });

/**
 *
 * @param {*} data
 */
export const getABookingRequest = bookingId => ({ type: AppFlowActions.GET_A_BOOKING_REQUEST, bookingId });

/**
 *
 * @param {*} data
 */
export const updateBookingRequest = data => ({ type: AppFlowActions.UPDATE_BOOKING_REQUEST, data });


/**
 *
 * @param {string} bookingId
 * @param {function} callback
 */
export const deleteBookingRequest = (bookingId, callback) =>
  ({ type: AppFlowActions.DELETE_BOOKING_REQUEST, bookingId, callback });
