import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
} from 'reactstrap';


const DeleteModal = ({
  isOpen,
  onDelete,
  onClose,
  title,
  isDeleting,
  submitText,
  cancelText,
  description,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <Media heading>
          {description}
        </Media>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" disabled={isDeleting} onClick={onDelete}>
          {isDeleting ? 'Requesting! Please wait...' : submitText}
        </Button>
        <Button color="primary" disabled={isDeleting} onClick={onClose}>
          {cancelText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  isOpen: false,
  isDeleting: false,
  submitText: 'Submit',
  cancelText: 'Cancel',
  description: 'description',
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  description: PropTypes.string,
}

export default DeleteModal;
