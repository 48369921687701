import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  BootstrapTable,
  TableHeaderColumn,
  SizePerPageDropDown,
} from 'react-bootstrap-table';
import {
  InputGroup, Input,
  InputGroupAddon, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { generateList, addSearchValues } from '../../utils';
import DeleteModal from '../DeleteModal';

const ListUsers = ({
  users,
  handleDeleteUser,
  isSuperAdmin,
  handleUpdateUser,
}) => {
  const [search, setSearch] = useState('')
  const [isDeleting, setIsDeleting] = useState(false);
  const [userIdDelete, setUserIdDelete] = useState(null);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const renderPageList = () => {
    const data = Object.keys(users).map(key => users[key]);

    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    return listPage;
  };

  const renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => onToggleDropDown(props.toggleDropDown)}
    />
  )

  const onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  const onCloseDeleteModal = () => {
    if (!isDeleting) {
      setUserIdDelete(null);
    }
  };

  const onOpenDeleteModal = id => () => setUserIdDelete(id);

  const onDeleteUser = () => {
    setIsDeleting(true);
    handleDeleteUser(userIdDelete, () => {
      setIsDeleting(false);
      setUserIdDelete(null);
    });
  };

  const renderActions = (cell, row) => {
    return (
      <Fragment>
        <Button
          className="mr-2"
          onClick={onClickUpdateAction(row.id)}
        >
          Edit
        </Button>
        <Button
          color="danger"
          onClick={onOpenDeleteModal(row.id)}
        >
          Delete
        </Button>
      </Fragment>
    );
  };

  const renderNo = (cell, { index }) => <div>{index + 1}</div>


  const renderPermissions = (cell, { permissions }) => {
    if (isEmpty(permissions)) return <div>NONE</div>;

    const permissionsKey = Object.keys(permissions.permissions);
    return (
      <div>
        {permissionsKey.map(item => <div key={item}>{item}</div>)}
      </div>
    );
  };

  const renderDepartment = (cell, { department }) => {
    if (isEmpty(department)) return <div>NONE</div>;

    const departmentChoice = {
      "repair_email": "Body repair", 
      "glass_email": "Glass repair", 
      "cleaning_email": "Cleaning service",
    };
    return (
      <div>{departmentChoice[department]}</div>
    );
  };

  const renderCreatedAt = (cell, { createdAt }) => {
    return (
      <div>
        {moment(createdAt * 1000).format('YYYY-MM-DD')}
      </div>
    );
  };

  const onClickUpdateAction = id => () => handleUpdateUser(id);

  const _users = Object.keys(users).map(key => users[key]);
  const dataFilter = generateList(_users, search);

  if (_users) {
    _.forEach(_users, (value) => {
      addSearchValues(value, value,
        ['userName',]);
    });
  }

  return (
    <div className="machines">
      <InputGroup
        className="form-group form-group-sm react-bs-table-search-form
        input-group input-group-sm input-search__remove-border"
      >
        <Input
          type="text"
          id="search"
          placeholder="Search"
          onChange={onSearch}
          value={search}
        />
        <InputGroupAddon
          className="input-group-addon input-search__overlap"
          addonType="prepend"
        >
          <FontAwesomeIcon icon="search" />
        </InputGroupAddon>
      </InputGroup>
      <BootstrapTable
        data={dataFilter}
        options={{
          sizePerPageList: renderPageList(),
          sortIndicator: true,
          hidePageListOnlyOnePage: true,
          sizePerPageDropDown: renderSizePerPageDropDown,
        }}
        pagination
        striped
        multiColumnSort={3}
      >
        <TableHeaderColumn
          isKey
          dataField="id"
          width="50"
          dataAlign="center"
          dataFormat={renderNo}
        >
          No
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="userName"
          dataAlign="center"
        >
          User Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderPermissions}
        >
          Permissions
        </TableHeaderColumn>
        <TableHeaderColumn
          dataFormat={renderDepartment}
          dataAlign="center"
        >
          Department
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderCreatedAt}
        >
          Created At
        </TableHeaderColumn>
        {isSuperAdmin && (
          <TableHeaderColumn
            dataAlign="center"
            dataFormat={renderActions}
            width="300"
          >
            Actions
          </TableHeaderColumn>
        )}
      </BootstrapTable>
      <DeleteModal
        isOpen={!!userIdDelete}
        onClose={onCloseDeleteModal}
        isDeleting={isDeleting}
        title="Delete user"
        submitText="Delete user"
        description="Are you sure delete user?"
        onDelete={onDeleteUser}
      />
    </div>
  );
}

ListUsers.defaultProps = {
  users: {},
}

ListUsers.propTypes = {
  user: PropTypes.object,
  handleDeleteUser: PropTypes.func,
  isSuperAdmin : PropTypes.bool,
  handleUpdateUser: PropTypes.func,
}

export default ListUsers;
