import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Jumbotron, Col, Row,
  Label, Button,
  Input, FormGroup,
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap';
import loadingPage from '../../Redux/Actions/loading';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { map, isEmpty, remove } from 'lodash';
import toastr from 'toastr';
import InputDatePicker from '../InputDatePicker';

import { fetchAllOccuppiedLock } from '../../Redux/Helpers/fetch';
import { checkNull, date2UtcSecondEpoch } from '../../utils';

class CreateBookingUserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unoccupiedLocks: Array.from({ length: 50 }, (v, k) => k + 1),
      invalidField: '',
      selectCheckinDate: new Date(),
      isKeySubstituteVehicle: false,
      serialMachine: '',
      keyPositionSubstituteVehicle: ''
    };

    this.initData();
  }

  initData = () => {
    this.dataUser = {
      customerName: '',
      phoneNumber: '',
      plateNumber: '',
      checkInDate: new Date(),
      repairNotes: '',
      dropdownMachine: false,
      dropdownKeySubstitute: false,
      isKeySubstituteVehicle: false,
      serialMachine: '',
      machineName: '',
      keyPositionSubstituteVehicle: '',
      plateNumberSubstituteVehicle: '',
    };
  }

  fetchOccuppiedLock = async (serialMachine, dateFilter) => {
    const result = await fetchAllOccuppiedLock(serialMachine, dateFilter);
    return result;
  }

  onChangeUserInfo = (e) => {
    const { value, name } = e.target;
    const { invalidField } = this.state;

    if (invalidField !== '') {
      this.setState({ invalidField: '' });
    }

    this.dataUser[name] = value;
  }

  onChangeIsKeySubstituteVehicle = () => {
    this.dataUser.isKeySubstituteVehicle = !this.dataUser.isKeySubstituteVehicle;
    if (!this.dataUser.isKeySubstituteVehicle) {
      // this.dataUser.serialMachine = '';
      // this.dataUser.machineName = '';
      this.dataUser.keyPositionSubstituteVehicle = '';
      this.dataUser.plateNumberSubstituteVehicle = '';
    }
    this.setState((prevState) => ({isKeySubstituteVehicle: !prevState.isKeySubstituteVehicle}));
  }

  onBookClick = (e) => {
    e.preventDefault();

    const dataCheckNull = [
      { field: 'customerName', value: this.dataUser.customerName },
      { field: 'phoneNumber', value: this.dataUser.phoneNumber },
      { field: 'plateNumber', value: this.dataUser.plateNumber },
      { field: 'machineName', value: this.dataUser.machineName },
    ];

    if (this.dataUser.isKeySubstituteVehicle) {
      dataCheckNull.push(
        { field: 'keyPositionSubstituteVehicle', value: this.dataUser.keyPositionSubstituteVehicle },
        { field: 'plateNumberSubstituteVehicle', value: this.dataUser.plateNumberSubstituteVehicle },
      );
    }

    const invalidValue = checkNull(dataCheckNull);

    if (invalidValue) {
      this.setState({ invalidField: invalidValue });

      return;
    }

    this.dataUser.checkInDate = date2UtcSecondEpoch(this.dataUser.checkInDate);
    this.dataUser.phoneNumber = `+${this.dataUser.phoneNumber}`;
    this.props.handleFinishBooking(this.dataUser);
  }

  onChangeReturnDate = (date) => {
    this.dataUser.checkInDate = date;
    this.setState({ selectCheckinDate: date });
  }

  onChangeReturnMachine = ({name, serialMachine}) => {
    const { selectCheckinDate } = this.state;
    this.setState({
      serialMachine,
      keyPositionSubstituteVehicle: ''
    });
    this.dataUser.serialMachine = serialMachine;
    this.dataUser.machineName = name;
    this.dataUser.keyPositionSubstituteVehicle = '';

    this.fetchOccuppiedLock(serialMachine, date2UtcSecondEpoch(selectCheckinDate)).then((response) => {
      try {
        if (response.error) {
          this.props.loadingPage(false);
          toastr.error(response.message, 'Error');
          return null;
        }
        this.setState({
          unoccupiedLocks: this.generateUnoccupiedLocks(response),
        });
        return response;
      } catch (error) {
        console.log('fetchOccuppiedLock', error);
        this.props.loadingPage(false);
        toastr.error(error, 'Error');
        return null;
      }
    });
  }

  onChangeKeyPositionSubstituteVehicle = (value) => {
    this.dataUser.keyPositionSubstituteVehicle = value;
    this.setState({keyPositionSubstituteVehicle: value});
  }

  generateUnoccupiedLocks = (occupiedLocks) => {
    const unoccupiedLocks = Array.from({ length: 50 }, (v, k) => k + 1);
    remove(unoccupiedLocks, n => occupiedLocks.indexOf(n) > -1);

    return unoccupiedLocks;
  }

  toggleDropdownMachine = () => {
    this.setState(prevState => ({ dropdownMachine: !prevState.dropdownMachine }));
  }

  toggleKeySubstitute = () => {
    this.setState(prevState => ({ dropdownKeySubstitute: !prevState.dropdownKeySubstitute }));
  }

  renderMachineItem = () => {
    const { machines } = this.props;
    const keys = Object.keys(machines);
    return (
      map(keys, (key, index) => (
        <DropdownItem
          name="machineName"
          key={index}
          onClick={() => { this.onChangeReturnMachine(machines[key]); }}
        >
          {machines[key].name}
        </DropdownItem>
      ))
    );
  }

  renderKeySubstituteItem = () => {
    const { unoccupiedLocks } = this.state;

    return (
      map(unoccupiedLocks, (value, index) => (
        <DropdownItem
          name="keyPosition"
          key={index}
          onClick={() => { this.onChangeKeyPositionSubstituteVehicle(value); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  isDisableKeyPositionSubstituteVehicle = () => {
    const { isKeySubstituteVehicle, serialMachine, machineName } = this.dataUser;
    if (!isKeySubstituteVehicle || isEmpty(serialMachine) || machineName === 'Select machine') {
      return true;
    }

    return false;
  }

  render = () => {
    const {
      invalidField,
      dropdownMachine,
      dropdownKeySubstitute,
    } = this.state;

    const {
      customerName, plateNumber,
      repairNotes,
      machineName, keyPositionSubstituteVehicle, isKeySubstituteVehicle,
    } = this.dataUser;

    return (
      <Jumbotron className="create-booking__jumbotron --child">
        <Col md={{ offset: 2, size: 8 }}>
          <form
            className="create-booking__form mt-4 form-horizontal"
            onSubmit={this.onBookClick}
          >
            <Row>
              <Col xs="12">
                <h2 className="text-center mb-4">
                  <strong>
                    User Information
                  </strong>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Customer name:
                  </Label>
                  <Input
                    className={invalidField === 'customerName' ? 'is-invalid' : ''}
                    type="text"
                    name="customerName"
                    placeholder="Customer Name"
                    defaultValue={customerName}
                    onChange={this.onChangeUserInfo}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6" md={{ size: '5', offset: '1' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Phone number:
                  </Label>
                  <Input
                    className={invalidField === 'phoneNumber' ? 'is-invalid form-control' : 'form-control'}
                    type="number"
                    name="phoneNumber"
                    placeholder="(Country Calling Codes)Phone Number"
                    // mask="9"
                    // maskChar={null}
                    // defaultValue={phoneNumber}
                    onChange={this.onChangeUserInfo}
                    // ref={this.myInputMaskRefPhone}
                  />
                </FormGroup>
              </Col>
              <Col xs="6" md={{ size: '5' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Plate number:
                  </Label>
                  <Input
                    className={invalidField === 'plateNumber' ? 'is-invalid form-control' : 'form-control'}
                    name="plateNumber"
                    placeholder="Plate Number"
                    defaultValue={plateNumber}
                    onChange={this.onChangeUserInfo}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Check-in date:
                  </Label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.selectCheckinDate}
                    onChange={(date) => { this.onChangeReturnDate(date); }}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                        // maxDate={maxDate}
                    customInput={<InputDatePicker />}
                    // showTimeSelect
                    // timeFormat="hh:mm"
                  />
                </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="w-100 ml-0">
                  <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    Check-in machine:
                  </Label>
                  <Dropdown
                    isOpen={dropdownMachine}
                    toggle={this.toggleDropdownMachine}
                  >
                    <DropdownToggle
                      className={invalidField === 'machineName' ? 'is-invalid' : ''}
                      caret
                    >
                      {machineName || 'Select machine'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.renderMachineItem()}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Repair note:
                  </Label>
                  <Input
                    className={invalidField === 'repairNotes' ? 'is-invalid' : ''}
                    style={{ height: '12rem' }}
                    type="textarea"
                    name="repairNotes"
                    placeholder="Repair Note"
                    defaultValue={repairNotes}
                    onChange={this.onChangeUserInfo}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="ml-4">
                  <Label check className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    <Input
                      type="checkbox"
                      name="isKeySubstituteVehicle"
                      onChange={this.onChangeIsKeySubstituteVehicle}
                    />
                    Use substitute vehicle
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            {isKeySubstituteVehicle ? (
              <Row>
                <Col xs="12" md={{ size: '10', offset: '1' }}>
                  <FormGroup row className="ml-0">
                    <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                      Substitute vehicle key position:
                    </Label>
                    <Col>
                      <Dropdown isOpen={dropdownKeySubstitute} toggle={this.toggleKeySubstitute}>
                        <DropdownToggle
                          className={invalidField === 'keyPositionSubstituteVehicle' ? 'is-invalid' : ''}
                          caret
                          disabled={this.isDisableKeyPositionSubstituteVehicle()}
                        >
                          {keyPositionSubstituteVehicle || 'Select key position'}
                        </DropdownToggle>
                        <DropdownMenu right>
                          {this.renderKeySubstituteItem()}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            ) : ''}
            {isKeySubstituteVehicle ? (
              <Row>
                <Col xs="12" md={{ size: '10', offset: '1' }}>
                  <FormGroup row className="ml-0">
                    <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                      Substitute vehicle plate number:
                    </Label>
                    <Col>
                      <Input
                        className={invalidField === 'plateNumberSubstituteVehicle' ? 'is-invalid' : ''}
                        type="text"
                        name="plateNumberSubstituteVehicle"
                        id="plateNumberSubstituteVehicle"
                        onChange={this.onChangeUserInfo}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            ) : ''}
            <Row className="mt-5 mb-4 justify-content-center">
              <Button
                className="create-booking__btn create-booking__btn-finish"
                onClick={this.onBookClick}
              >
                Add Booking
              </Button>
            </Row>
          </form>
        </Col>
      </Jumbotron>
    );
  }
}

CreateBookingUserInfo.propTypes = {
  handleFinishBooking: PropTypes.func.isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    machines: state.machines,
  };
}

const mapDispatchToProps = {
  loadingPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBookingUserInfo);
