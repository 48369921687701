import initialState from './initialState';
import AppFlowActions from '../../constants';

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case AppFlowActions.GET_A_USER_COMPLETE:
      const { user } = action;
      if (user) return user;
      return state;
    default:
      return state;
  }
};

export default user;
