/* eslint-disable no-console */
import $ from 'jquery';

import { RemoteControl } from '../../remoteControlContants';

window.jQuery = $;
window.$ = $;

require('../../assets/js/strophe.umd.min.js');
require('../../assets/js/strophe.disco.min.js');

// const JitsiMeetJS = require('../../assets/js/lib-jitsi-meet.min.js');

export const JitsiConferenceEvents = JitsiMeetJS.events.conference;

JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

const singletonEnforcer = Symbol('jitsi');

class Jitsi {
  secretKey;

  jitsiConnectionClient;

  room;

  confOptions;

  remoteTracks;

  localTracks;

  isJoined;

  options;

  eventEmitter;

  isVideo;

  roomName;

  static jitsiInstance;

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot initialize Cookie single instance');
    }

    this.options = {
      hosts: {
        domain: 'meet.jitsi',
        muc: 'muc.meet.jitsi',
      },
      disableSimulcast: false,
      enableRemb: false,
      enableTcc: true,
      resolution: 720,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: {
            ideal: 720,
            max: 720,
            min: 180,
          },
          width: {
            ideal: 1280,
            max: 1280,
            min: 320,
          },
        },
      },
      externalConnectUrl: '//streaming.digitalfortress.dev/http-pre-bind',
      p2pStunServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
      ],
      enableP2P: true,
      p2p: {
        enabled: true,
        preferH264: true,
        disableH264: true,
        useStunTurn: true,
        stunServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' },
          { urls: 'stun:stun2.l.google.com:19302' },
        ],
      },
      useStunTurn: true,
      useIPv6: false,
      useNicks: false,
      serviceUrl: 'https://streaming.digitalfortress.dev/http-bind',
      openBridgeChannel: 'websocket',
      channelLastN: -1,
      minHDHeight: 540,
      startBitrate: '800',
      useRtcpMux: true,
      useBundle: true,
      disableSuspendVideo: true,
      stereo: false,
      forceJVB121Ratio: -1,
      enableTalkWhileMuted: true,

      enableClosePage: true,
      startWithVideoMuted: true,
    };

    this.confOptions = {
      openBridgeChannel: true,
    };

    this.isJoined = false;
    this.room = null;
    this.localTracks = [];
    this.remoteTracks = {};

    $(window).bind('beforeunload', this.unload);
    $(window).bind('unload', this.unload);
  }

  static get instance() {
    if (!this.jitsiInstance) {
      this.jitsiInstance = new Jitsi(singletonEnforcer);
    }

    return this.jitsiInstance;
  }

  initLib = (config) => {
    JitsiMeetJS.init({
      disableAudioLevels: true,
      ...config,
    });
  };

  connect = (eventEmitter) => {
    this.eventEmitter = eventEmitter;
    this.options.serviceUrl = `https://streaming.digitalfortress.dev/http-bind?room=${this.roomName}`;
    this.jitsiConnectionClient = new JitsiMeetJS.JitsiConnection(
      null,
      null,
      this.options,
    );

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        if (devices.findIndex(device => device.kind === 'videoinput') !== -1) {
          this.isVideo = true;
        } else {
          this.isVideo = false;
        }
      })
      .catch((err) => {
        console.error('Error enumerating devices:', err);
    });

    this.jitsiConnectionClient.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess,
    );
    this.jitsiConnectionClient.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed,
    );
    this.jitsiConnectionClient.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.disconnect,
    );

    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      this.onDeviceListChanged,
    );

    this.jitsiConnectionClient.connect();
  };

  unload = () => {
    try {
      for (let i = 0; i < this.localTracks.length; i++) {
        if (this.localTracks && this.localTracks[i]) {
          this.localTracks[i].dispose();
        }
      }

      if (this.room) {
        this.room.leave();
      }
      if (this.jitsiConnectionClient) {
        this.jitsiConnectionClient.disconnect();
      }

      localStorage.setItem(`isInitStream_${RemoteControl.PROJECT_ID}`, false);
    } catch (error) {
      console.log('unload error', error);
    }
  };

  onConnectionSuccess = () => {
    console.log('onConnectionSuccess', this.jitsiConnectionClient);
    this.room = this.jitsiConnectionClient.initJitsiConference(
      this.roomName || 'defaultRoom',
      this.options,
    );

    JitsiMeetJS.createLocalTracks({ devices: [] })
      .then(this.onLocalTracks)
      .catch((error) => {
        throw error;
      });
    this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
    this.room.on(
      JitsiMeetJS.events.conference.TRACK_REMOVED,
      this.onTrackRemoved,
    );
    this.room.on(
      JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      this.onConferenceJoined,
    );
    this.room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
      console.log('user join');
      this.remoteTracks[id] = [];
    });
    this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
    this.room.setDisplayName('clientName');
    this.room.join();
  };

  onConferenceJoined = () => {
    console.log('conference joined!');
    this.isJoined = true;
    for (let i = 0; i < this.localTracks.length; i++) {
      this.room.addTrack(this.localTracks[i]);
    }
  };

  onRemoteTrack = (track) => {
    if (track.isLocal()) {
      return;
    }
    const participant = track.getParticipantId();

    if (!this.remoteTracks[participant]) {
      this.remoteTracks[participant] = [];
    }
    const idx = this.remoteTracks[participant].push(track);
    const id = participant + track.getType() + idx;

    if (track.getType() === 'audio') {
      $('#player').append(
        `<audio autoplay='1' id='${participant}audio${idx}' />`
      );
    }

    this.eventEmitter.emit(JitsiConferenceEvents.TRACK_ADDED, {
      participant,
      idx,
      track,
    });
    track.attach($(`#${id}`)[0]);
    for (let i = 0; i < this.localTracks.length; i++) {
      if (this.localTracks[i].getType() === 'video') {
        this.localTracks[i].attach($(`#localVideo${i}`)[0]);
      } else {
        this.localTracks[i].attach($(`#localAudio${i}`)[0]);
      }
    }
  };

  onTrackRemoved = (track) => {
    this.eventEmitter.emit(JitsiConferenceEvents.TRACK_REMOVED, track);
  };

  onLocalTracks = (tracks) => {
    this.localTracks = tracks;
    for (let i = 0; i < this.localTracks.length; i++) {
      if (this.localTracks[i].getType() === 'video') {
        this.eventEmitter.emit(JitsiConferenceEvents.GET_LOCAL_TRACK, {
          idx: `localVideo${i}`,
          track: this.localTracks[i],
        });
        this.localTracks[i].attach($(`#localVideo${i}`)[0]);
      } else {
        this.eventEmitter.emit(JitsiConferenceEvents.GET_LOCAL_TRACK, {
          idx: `localAudio${i}`,
          track: this.localTracks[i],
        });
        this.localTracks[i].attach($(`#localAudio${i}`)[0]);
      }
      if (this.isJoined) {
        this.room.addTrack(this.localTracks[i]);
      }
    }
  };

  onUserLeft(id) {
    console.log('user left');
    if (!this.remoteTracks || (this.remoteTracks && !this.remoteTracks[id])) {
      return;
    }
    const tracks = this.remoteTracks[id];

    for (let i = 0; i < tracks.length; i++) {
      tracks[i].detach($(`#${id}${tracks[i].getType()}`));
    }
  }

  setRoomName = (name) => {
    this.roomName = name;
  };

  onConnectionFailed() {
    console.error('Connection Failed!');
  }

  onDeviceListChanged(devices) {
    console.info('current devices', devices);
  }

  disconnect() {
    console.log('disconnect!');
    if (this.jitsiConnectionClient) {
      this.jitsiConnectionClient.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionSuccess,
      );
      this.jitsiConnectionClient.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.onConnectionFailed,
      );
      this.jitsiConnectionClient.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.disconnect,
      );
    }
  }
}

export default Jitsi.instance;
