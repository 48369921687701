import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CreateMachine from '../CreateMachine';
import CreateBooking from '../CreateBooking';
import PortalMachines from '../PortalMachines';
import MachineDetail from '../MachineDetail';
import PortalBookings from '../PortalBookings';
import BookingDetail from '../BookingDetail';
// import PortalAssets from '../PortalAssets';
// import VehicleDetail from '../VehicleDetail';
import PortalUsers from '../PortalUsers';
import CreateUser from '../CreateUser';
import UpdateUser from '../UpdateUser';
import UpdateMachine from '../UpdateMachine';
import ChangePassword from '../ChangePassword';
import PortalQrCode from '../PortalQrCode';
import CreateQRCode from '../CreateQRCode';

// import auth from '../../Redux/Helpers/auth';
import getAllRequest from '../../Redux/Actions/getall';
import reloadPageRequest from '../../Redux/Actions/reload';
import getAllMachineRequest from '../../Redux/Actions/machine';
import { getAUserRequest } from '../../Redux/Actions/users';

const menuAdmin = [
  {
    id: 0,
    name: 'Machines',
    link: '/admin/machines',
    icon: 'server',
  },
  {
    id: 1,
    name: 'Bookings',
    link: '/admin/bookings',
    icon: 'id-badge',
  },
];

class Full extends Component {
  componentDidMount = () => {
    this.props.getAUserRequest();

    this.startTimerStatus();

    window.onbeforeunload = () => {
      this.props.reloadPageRequest();
    };

    window.onload = () => {
      this.props.getAllRequest();
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.timerStatus);
  }

  startTimerStatus = () => {
    if (!this.timerStatus) {
      this.timerStatus = setInterval(() => {
        this.props.getAllMachineRequest();
      }, 60 * 1000);
    }
  }

  sendCommand = async (
    commandMessage,
  ) => {
    // [START iot_send_command]
    // const cloudRegion = 'us-central1';
    // const deviceId = 'my-device';
    // const commandMessage = 'message for device';
    // const projectId = 'adjective-noun-123';
    // const registryId = 'my-registry';
    const iotClient = new iot.v1.DeviceManagerClient({
      // optional auth parameters.
    });

    const formattedName = iotClient.devicePath(
      projectId,
      cloudRegion,
      registryId,
      deviceId,
    );
    const binaryData = Buffer.from(commandMessage);
    const request = {
      name: formattedName,
      binaryData,
    };

    try {
      const responses = await iotClient.sendCommandToDevice(request);

      console.log('Sent command: ', responses[0]);
    } catch (err) {
      console.error('Could not send command:', err);
    }
  };

  renderMenuAdmin = () => {
    const { user } = this.props;

    if (user.isSuperAdmin) {
      return [
        ...menuAdmin,
        {
          id: 2,
          name: 'Users',
          link: '/admin/users',
          icon: 'user',
        },
        {
          id: 3,
          name: 'Towing Comany',
          link: '/admin/qrcode',
          icon: 'qrcode',
        },
      ];
    }

    return menuAdmin;
  }

  render = () => (
    <div className="app">
      <Header userName={this.props.user.userName || 'Admin'} history={this.props.history} />
      <div id="app-body" className="app-body">
        <Sidebar menu={this.renderMenuAdmin()} />
        <Switch>
          <Route exact path="/admin/machines" name="portal-machines" component={PortalMachines} />
          <Route path="/admin/machines/:serialMachine" name="detail-machine" component={MachineDetail} />
          <Route exact path="/admin/bookings" name="portal-bookings" component={PortalBookings} />
          <Route path="/admin/bookings/new/:bookingId" name="detail-new" component={BookingDetail} />
          <Route path="/admin/bookings/checkedin/:bookingId" name="detail-checkin" component={BookingDetail} />
          <Route path="/admin/bookings/completed/:bookingId" name="detail-checkout" component={BookingDetail} />
          <Route path="/admin/bookings/done/:bookingId" name="detail-checkout" component={BookingDetail} />
          <Route path="/admin/createmachine" name="create-machine" component={CreateMachine} />
          <Route path="/admin/createbooking" name="create-booking" component={CreateBooking} />
          <Route path="/admin/updatemachine/:serialMachine" name="update-machine" component={UpdateMachine} />
          <Route path="/admin/changepassword" name="change-password" component={ChangePassword} />
          {this.props.user.isSuperAdmin && (
            <Fragment>
              <Route path="/admin/users" name="users" component={PortalUsers} />
              <Route path="/admin/createuser" name="create-user" component={CreateUser} />
              <Route path="/admin/updateuser/:userId" name="update-user" component={UpdateUser} />
              <Route path="/admin/qrcode" name="qr-code" component={PortalQrCode} />
              <Route path="/admin/createqrcode" name="create-qr-code" component={CreateQRCode} />
            </Fragment>
          )}
          <Redirect from="/" to="/admin/machines" />
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

Full.propTypes = {
  reloadPageRequest: PropTypes.func.isRequired,
  getAllRequest: PropTypes.func.isRequired,
  getAllMachineRequest: PropTypes.func.isRequired,
  getAUserRequest: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  reloadPageRequest,
  getAllRequest,
  getAllMachineRequest,
  getAUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Full);
