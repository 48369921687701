export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    const login = state.login.error ? {} : state.login;
    return {
      ...state,
      loading: {
        ...state.loading,
        isLoading: false,
      },
      login,
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
