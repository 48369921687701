import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BootstrapTable,
  TableHeaderColumn,
  SizePerPageDropDown,
} from 'react-bootstrap-table';
import {
  InputGroup, Input,
  InputGroupAddon, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { isEmpty } from 'lodash';
import QRCode from 'qrcode.react';

import { generateList } from '../../utils';
import DeleteModal from '../DeleteModal';

const ListQrCode = ({
  qrCodes,
  handleDeleteQRCode,
}) => {
  const [search, setSearch] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [qrCodeIdDelete, setQrCodeIdDelete] = useState(null);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const renderPageList = () => {
    const data = Object.keys(qrCodes).map(key => qrCodes[key]);

    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    return listPage;
  };

  const renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => console.log('toggle')}
    />
  );

  const onCloseDeleteModal = () => {
    if (!isDeleting) {
      setQrCodeIdDelete(null);
    }
  };

  const onOpenDeleteModal = id => () => setQrCodeIdDelete(id);

  const onDeleteQRCode = () => {
    setIsDeleting(true);
    handleDeleteQRCode(qrCodeIdDelete, () => {
      setIsDeleting(false);
      setQrCodeIdDelete(null);
    });
  };

  const renderActions = (cell, { id }) => (
    <Button
      color="danger"
      onClick={onOpenDeleteModal(id)}
    >
      Delete
    </Button>
  );

  const renderNo = (cell, { index }) => <div>{index + 1}</div>;


  const renderQrCode = (cell, { QRCode: qrCode }) => (
    <QRCode
      value={qrCode}
    />
  );

  const renderCreatedAt = (cell, { createdAt }) => (
    <div>
      {moment(createdAt * 1000).format('YYYY-MM-DD')}
    </div>
  );

  const _qrCodes = Object.keys(qrCodes).map(key => qrCodes[key]);
  const dataFilter = generateList(_qrCodes, search);

  return (
    <div className="towing-company">
      <InputGroup
        className="form-group form-group-sm react-bs-table-search-form
        input-group input-group-sm input-search__remove-border"
      >
        <Input
          type="text"
          id="search"
          placeholder="Search"
          onChange={onSearch}
          value={search}
        />
        <InputGroupAddon
          className="input-group-addon input-search__overlap"
          addonType="prepend"
        >
          <FontAwesomeIcon icon="search" />
        </InputGroupAddon>
      </InputGroup>
      <BootstrapTable
        data={dataFilter}
        options={{
          sizePerPageList: renderPageList(),
          sortIndicator: true,
          hidePageListOnlyOnePage: true,
          sizePerPageDropDown: renderSizePerPageDropDown,
        }}
        pagination
        striped
        multiColumnSort={3}
      >
        <TableHeaderColumn
          isKey
          dataField="id"
          width="50"
          dataAlign="center"
          dataFormat={renderNo}
        >
          No
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="companyName"
          dataAlign="center"
        >
          Company name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          width="200"
          dataFormat={renderQrCode}
        >
          QR Code
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderCreatedAt}
        >
          Created At
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderActions}
          width="300"
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable>
      <DeleteModal
        isOpen={!!qrCodeIdDelete}
        onClose={onCloseDeleteModal}
        isDeleting={isDeleting}
        title="Delete Towing Company"
        submitText="Delete Towing Company"
        description="Are you sure delete Towing Company?"
        onDelete={onDeleteQRCode}
      />
    </div>
  );
};

ListQrCode.propTypes = {
  qrCodes: PropTypes.objectOf(PropTypes.object),
  handleDeleteQRCode: PropTypes.func,
};

export default ListQrCode;
