import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import UpdateMachineInfo from '../../components/UpdateMachineInfo';

import loadingPage from '../../Redux/Actions/loading';
import getAllMachineRequest, { updateMachineRequest } from '../../Redux/Actions/machine';
import { getAllUserRequest } from '../../Redux/Actions/users';

class CreateMachine extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.props.loadingPage(true);
    this.props.getAllUserRequest();
  }

  handleUpdateMachine = (body) => {
    const {
      onUpdateMachine,
      match: { params: { serialMachine } },
      history,
      onGetAllMachine,
    } = this.props;
    try {
      this.props.loadingPage(true);
      onUpdateMachine(serialMachine, body, () => {
        onGetAllMachine();
        history.push('/admin/machines');
      });
    } catch (error) {
      this.props.loadingPage(false);
      return false;
    }
  }

  render = () => {
    const { users, machines, match: { params: { serialMachine } } } = this.props;

    return (
      <div className="create-booking">
        <main className="main">
          <Container fluid>
            <Row className="booking-name">
              <Link to="/admin/machines">
                <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
              </Link>
              <div className="create-booking__header">
                <h2 className="text__header">
                  UPDATE MACHINE
                </h2>
              </div>
            </Row>
            <UpdateMachineInfo handleUpdateMachine={this.handleUpdateMachine} users={users} machine={machines[serialMachine]}/>
          </Container>
        </main>
      </div>
    );
  }
}

CreateMachine.propTypes = {
  loadingPage: PropTypes.func.isRequired,
  getAllUserRequest: PropTypes.func.isRequired,
  onUpdateMachine: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onGetAllMachine: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  users: PropTypes.shape().isRequired,
  machines: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
  machines: state.machines,
});

const mapDispatchToProps = {
  loadingPage,
  getAllUserRequest,
  onUpdateMachine: updateMachineRequest,
  onGetAllMachine: getAllMachineRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMachine);
