import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAllUserRequest, deleteUserRequest } from '../../Redux/Actions/users';
import loadingPage from '../../Redux/Actions/loading';

import ListUsers from '../../components/ListUsers';

class PortalUsers extends Component {
  state = {}

  componentDidMount = () => {
    const { getAllUserRequest } = this.props;
    this.props.loadingPage(true);
    getAllUserRequest();
  }

  onClickCreate = () => {
    this.props.history.push('/admin/createuser');
  }

  handleDeleteUser = (id, callback) => {
    const { deleteUserRequest, loadingPage } = this.props;

    try {
      loadingPage(true);
      deleteUserRequest(id, callback);
    } catch (error) {
      loadingPage(false);
    }
  }

  handleUpdateUser = id =>
    this.props.history.push(`/admin/updateuser/${id}`);

  render = () => {
    const { users, user, match: { params: { userId } }} = this.props;

    return (
      <div className="portal app-body">
        <div className="main">
          <Container fluid>
            <Row>
              <Col xs="6">
                <h2 className="mt-3 mb-3 text__header">
                  USERS MANAGEMENT
                </h2>
              </Col>
              <Col xs="6" className="align-self-center text-right">
                <Button
                  className="btn"
                  onClick={this.onClickCreate}
                >
                  Add User
                </Button>
              </Col>
            </Row>
            <Row>
              <ListUsers
                users={users}
                handleDeleteUser={this.handleDeleteUser}
                isSuperAdmin={user.isSuperAdmin}
                handleUpdateUser={this.handleUpdateUser}
              />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

PortalUsers.defaultProps = {
  users: {},
};

PortalUsers.propTypes = {
  users: PropTypes.objectOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
  user: state.user,
})

const mapDispatchToProps = {
  getAllUserRequest,
  loadingPage,
  deleteUserRequest,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalUsers));
