import AppFlowActions from '../../constants';

/**
 *
 * @param {*}
 */
export const getAllQRCodesRequest = () => ({
  type: AppFlowActions.GET_ALL_QR_CODE_REQUEST,
});

/**
 *
 * @param {object} qrCode
 * @param {function} callback
 */

export const createQRCodeRequest = (qrCode, callback) => ({
  type: AppFlowActions.CREATE_QR_CODE_REQUEST, qrCode, callback,
});


/**
 *
 * @param {string} qrCodeId
 * @param {function} callback
 */

export const deleteQRCodeRequest = (qrCodeId, callback) => ({
  type: AppFlowActions.DELETE_QR_CODE_REQUEST, qrCodeId, callback,
});