import { isEmpty } from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

const qrCodes = (state = initialState.qrCodes, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_QR_CODE_COMPLETE: {
      if (!isEmpty(action.qrCodes)) return action.qrCodes;

      return state;
    }
    case AppFlowActions.CREATE_QR_CODE_COMPLETE: {
      const { qrCode } = action;
      if (isEmpty(qrCode)) return state;

      state[qrCode.id] = qrCode;
      return { ...state };
    }
    case AppFlowActions.DELETE_QR_CODE_COMPLETE: {
      const { qrCodeId } = action;
      if (isEmpty(qrCodeId)) return state;

      delete state[qrCodeId];
      return { ...state };
    }
    default:
      return state;
  }
};

export default qrCodes;
