import { find } from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

function updateBooking(state, action) {
  const { data } = action;
  const booking = find(state, x => x._id === data.id);

  if (booking) {
    booking.vehicleInfo = data.idCar;
  }
}

function deleteBooking(state, { bookingId }) {
  const index = state.findIndex(({ id }) => id === bookingId);

  if (index > -1) {
    return [
      ...state.slice(0, index),
      ...state.slice(index + 1),
    ];
  }

  return state;
}

const bookings = (state = initialState.bookings, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_BOOKING_COMPLETE:
      if (action.booking) {
        const { booking } = action.booking;
        return booking;
      }
      return state;

    case AppFlowActions.UPDATE_BOOKING_COMPLETE:
    {
      return updateBooking(state, action);
    }
    case AppFlowActions.DELETE_BOOKING_COMPLETE:
    {
      return deleteBooking(state, action);
    }
    default:
      return state;
  }
};

export default bookings;
