import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import setSearchRequest from '../../Redux/Actions/search';
import { generateList, addSearchValues, formatDisplayDate } from '../../utils';
import DeleteModal from '../DeleteModal';

library.add(faSearch);

class New extends Component {
  state = {
    search: '',
    bookingIdDelete: null,
    isDeleting: false,
  }

  onChangeSearch = (e) => {
    const { value } = e.target;

    this.setState({ search: value });

    if (this.searchTimer !== null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.props.setSearchRequest({ bookingSearchString: value });
    }, 500);
  }

  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderPageList = () => {
    const { data } = this.props;
    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    const element = {
      text: 'All',
      value: data.length,
    };

    listPage.push(element);

    return listPage;
  }

  renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => this.onToggleDropDown(props.toggleDropDown)}
    />
  )

  onCloseDeleteModal = () => {
    if (!this.state.isDeleting) {
      this.setState({ bookingIdDelete: null });
    }
  };

  onOpenDeleteModal = bookingIdDelete => (e) => {
    e.stopPropagation();
    this.setState({ bookingIdDelete });
  }

  onDeleteBooking = () => {
    const { handleDeleteBooking } = this.props;
    this.setState({ isDeleting: true });
    handleDeleteBooking(this.state.bookingIdDelete, () => {
      this.setState({ bookingIdDelete: null, isDeleting: false });
    });
  };

  renderActions = (cell, row) => {
    return (
      <Button
        color="danger"
        onClick={this.onOpenDeleteModal(row.bookingId)}
      >
        Delete
      </Button>
    );
  };

  render = () => {
    const { search, isDeleting, bookingIdDelete } = this.state;
    const {
      data, history,
    } = this.props;

    const dataFilter = generateList(data, search);

    const renderDepartment = (cell, { department }) => {
      if (isEmpty(department)) return <div>NONE</div>;
  
      const departmentChoice = {
        "repair_email": "Body repair", 
        "glass_email": "Glass repair", 
        "cleaning_email": "Cleaning service"
      };
      return (
        <div>{departmentChoice[department]}</div>
      );
    };

    if (data) {
      _.forEach(data, (value) => {
        addSearchValues(value, value,
          ['bookingIndex', 'customerName', 'phoneNumber', 'plateNumber', 'checkinDate']);
      });
    }

    return (
      <div className="table-tab">
        <InputGroup
          className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm input-search__remove-border"
        >
          <Input
            // className="border-radius-right-0"
            type="text"
            placeholder="Search"
            onChange={this.onChangeSearch}
            defaultValue={search}
          />
          <InputGroupAddon className="input-group-addon input-search__overlap" addonType="prepend">
            <FontAwesomeIcon icon="search" />
          </InputGroupAddon>
        </InputGroup>
        <BootstrapTable
          data={_.orderBy(dataFilter, ['bookingIndex'], ['desc'])}
          className="cursor-pointer__table"
          options={{
            sizePerPageList: this.renderPageList(),
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            paginationSize: 4,
            onRowClick(row) {
              history.push(`../admin/bookings/new/${row.bookingId}`);
            },
          }}
          pagination
          striped
          hover
          multiColumnSort={3}
        >
          <TableHeaderColumn
            isKey
            dataField="bookingIndex"
            dataAlign="center"
            dataSort
            width="100"
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="customerName"
            dataAlign="center"
            dataSort
            width="150"
          >
            Customer Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="machineName"
            dataAlign="center"
            dataSort
            width="200"
          >
            Machine
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phoneNumber"
            dataAlign="center"
            dataSort
            width="150"
          >
            Phone Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="plateNumber"
            dataAlign="center"
            dataSort
            width="150"
          >
            Plate Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="checkInDate"
            dataAlign="center"
            dataSort
            width="150"
            dataFormat={(cell, row) => formatDisplayDate(cell)}
          >
            Check-in Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="department"
            dataAlign="center"
            dataSort
            width="150"
            dataFormat={renderDepartment}
          >
            Department
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataFormat={this.renderActions}
            width="100"
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
        <DeleteModal
          isOpen={!!bookingIdDelete}
          onClose={this.onCloseDeleteModal}
          isDeleting={isDeleting}
          title="Delete booking"
          submitText="Delete booking"
          description="Are you sure delete booking?"
          onDelete={this.onDeleteBooking}
        />
      </div>
    );
  }
}

New.defaultProps = {
  data: [],
};

New.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setSearchRequest: PropTypes.func.isRequired,
  handleDeleteBooking: PropTypes.func,
};

const mapDispatchToProps = {
  setSearchRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(New));
