import { fork } from 'redux-saga/effects';
import loginFlow from './login';
import getAllFlow from './getall';
import bookingFlow from './booking';
import machineFlow from './machine';
import searchFlow from './search';
import usersFlow from './users';
import qrCodesFlow from './qrCodes';

export default function* root() {
  yield fork(loginFlow);
  yield fork(getAllFlow);
  yield fork(bookingFlow);
  yield fork(machineFlow);
  yield fork(searchFlow);
  yield fork(usersFlow);
  yield fork(qrCodesFlow);
}
