import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Row, Jumbotron, DropdownToggle, Dropdown,
  FormGroup, Label, Input, DropdownItem, DropdownMenu,
  InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import moment from 'moment';
import { map, remove } from 'lodash';
import toastr from 'toastr';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import InputDatePicker from '../InputDatePicker';

import loadingPage from '../../Redux/Actions/loading';
import { fetchAllOccuppiedLock } from '../../Redux/Helpers/fetch';
import { date2UtcSecondEpoch, second2MiliSecondEpoch } from '../../utils';

library.add(faPencilAlt);

class RepairDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unoccupiedLocks: Array.from({ length: 50 }, (v, k) => k + 1),
      dateSelected: new Date(),
      dropdownStatus: false,
      dropdownMachine: false,
      dropdownSubstituteVehicleMachine: false,
      dropdownKey: false,
      dropdownDepartment: false,
      dropdownKeySubstitute: false,
    };

    this.fieldsStatus = {
      isDisableRepairCosts: false,
      isDisableReturnDate: false,
      isDisableStatus: false,
      isDisableReturnMachine: false,
      isDisableKeyPosition: false,
      isDisableRepairNotes: false,
      isDisableCheckboxUseSubstituteVehicle: false,
      isDisablePlateNumberSubstituteVehicle: false,
      isDisableKeyPositionSubstituteVehicle: false,
    };

    this.departmentChoice = {
      "repair_email": "Body repair", 
      "glass_email": "Glass repair", 
      "cleaning_email": "Cleaning service"
  };
  }

  disableAllFields = () => {
    this.fieldsStatus = {
      isDisableRepairCosts: true,
      isDisableReturnDate: true,
      isDisableStatus: true,
      isDisableReturnMachine: true,
      isDisableKeyPosition: true,
      isDisableRepairNotes: true,
      isDisableCheckboxUseSubstituteVehicle: true,
      isDisableKeyPositionSubstituteVehicle: true,
      isDisablePlateNumberSubstituteVehicle: true,
    };
  }

  getFieldsStatus = () => {
    const { dataRepairDetails, rootStatus } = this.props;
    if (!dataRepairDetails.status || !rootStatus) {
      return;
    }

    const status = dataRepairDetails.status.toLowerCase();
    const root = rootStatus.toLowerCase();

    switch (status) {
      case 'booking':
        this.fieldsStatus = {
          isDisableRepairCosts: true,
          isDisableReturnDate: true,
          isDisableStatus: true,
          // isDisableReturnMachine: true,
          isDisableKeyPosition: true,
          isDisableDepartment: true,
          isDisableRepairNotes: false,
          isDisableCheckboxUseSubstituteVehicle: false,
        };
        this.fieldsStatus.isDisableKeyPositionSubstituteVehicle = !this.props.dataRepairDetails.isKeySubstituteVehicle || !this.props.dataRepairDetails.serialMachine;
        this.fieldsStatus.isDisablePlateNumberSubstituteVehicle = !this.props.dataRepairDetails.isKeySubstituteVehicle;
        break;
      case 'checkedin':
        // this.fieldsStatus.isDisableReturnMachine = true;
        // this.fieldsStatus.isDisableKeyPosition = true;
        // this.fieldsStatus.isDisableCheckboxUseSubstituteVehicle = true;
        // this.fieldsStatus.isDisableKeyPositionSubstituteVehicle = true;
        // this.fieldsStatus.isDisablePlateNumberSubstituteVehicle = true;
        break;
      case 'completed':
        // if (root === 'checkedin') {
        //   this.fieldsStatus = {
        //     DisableReturnMachine: true,
        //     isDisableKeyPosition: true,
        //     isDisDisableRepairCosts: false,
        //     isDisableReturnDate: false,
        //     isDisableStatus: false,
        //     isisableRepairNotes: false,
        //     isDisableCheckboxUseSubstituteVehicle: true,
        //     isDisableKeyPositionSubstituteVehicle: true,
        //     isDisablePlateNumberSubstituteVehicle: true,
        //   };
        //   this.fieldsStatus.isDisableKeyPosition = this.isDisableKeyDropdown();
        // } else {
        //   this.disableAllFields();
        // }
        break;
      case 'done':
        if (root === 'done') {
          this.disableAllFields();
        }
        break;
      default:
        this.disableAllFields();
        break;
    }
  }

  fetchOccuppiedLock = async (serialMachine, dateFilter) => {
    const result = await fetchAllOccuppiedLock(serialMachine, dateFilter);
    return result;
  }

  onChangeCheckboxUseSubstituteVehicle = () => {
    this.props.isUseSubstituteVehicle(!this.props.dataRepairDetails.isKeySubstituteVehicle);
  }

  onChangeUserInfo = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  onChangeReturnDate= (date) => {
    this.setState({ dateSelected: date });
  }

  onChangeReturnMachine = (machine) => {
    this.props.onDropDownChangedRepairDetails('machine', machine);
    this.showKeyAvailable(machine.serialMachine);
  }

  showKeyAvailable = (serialMachine) => {
    const { dateSelected } = this.state;
    this.fetchOccuppiedLock(serialMachine, date2UtcSecondEpoch(dateSelected)).then((response) => {
      try {
        if (response.error) {
          this.props.loadingPage(false);
          toastr.error(response.message, 'Error');
          return null;
        }
        this.setState({
          unoccupiedLocks: this.generateUnoccupiedLocks(response),
        });
        return response;
      } catch (error) {
        console.log('fetchOccuppiedLock', error);
        this.props.loadingPage(false);
        toastr.error(error, 'Error');
        return null;
      }
    });
  }

  generateUnoccupiedLocks = (occupiedLocks) => {
    const unoccupiedLocks = Array.from({ length: 50 }, (v, k) => k + 1);
    remove(unoccupiedLocks, n => occupiedLocks.indexOf(n) > -1);

    return unoccupiedLocks;
  }

  toggleBookingStatus = () => {
    this.setState(prevState => ({ dropdownStatus: !prevState.dropdownStatus }));
  }

  toggleMachine = () => {
    this.setState(prevState => ({ dropdownMachine: !prevState.dropdownMachine }));
  }

  toggleSubstituteVehicleMachine = () => {
    this.setState(prevState => ({ dropdownSubstituteVehicleMachine: !prevState.dropdownSubstituteVehicleMachine }));
  }

  toggleKey = () => {
    this.setState(prevState => ({ dropdownKey: !prevState.dropdownKey }));
  }

  toggleDepartment = () => {
    this.setState(prevState => ({ dropdownDepartment: !prevState.dropdownDepartment }));
  }

  toggleKeySubstitute = () => {
    this.setState(prevState => ({ dropdownKeySubstitute: !prevState.dropdownKeySubstitute }));
  }

  renderStatusItem = () => {
    const statusItems = ['Checkedin', 'Completed', 'Done'];

    return (
      map(statusItems, (value, index) => (
        <DropdownItem
          name="status"
          key={index}
          onClick={() => { this.props.onDropDownChangedRepairDetails('status', value); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  renderMachineItem = () => {
    const { machines } = this.props;
    const keys = Object.keys(machines);

    return (
      map(keys, (key, index) => (
        <DropdownItem
          name="machineName"
          key={index}
          onClick={() => { this.onChangeReturnMachine(machines[key]); }}
        >
          {machines[key].name}
        </DropdownItem>
      ))
    );
  }

  renderKeyItem = () => {
    const { unoccupiedLocks } = this.state;

    return (
      map(unoccupiedLocks, (value, index) => (
        <DropdownItem
          name="keyPosition"
          key={index}
          onClick={() => { this.props.onDropDownChangedRepairDetails('keyPosition', value); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  renderDepartmentItem = () => {
    return (
      Object.keys(this.departmentChoice).map((key) => (
        <DropdownItem
          name="department"
          key={key}
          onClick={() => { this.props.onDropDownChangedRepairDetails('department', key); }}
        >
          {this.departmentChoice[key]}
        </DropdownItem>
      ))
    );
  }


  renderKeySubstituteItem = () => {
    const { unoccupiedLocks } = this.state;

    return (
      map(unoccupiedLocks, (value, index) => (
        <DropdownItem
          name="keyPosition"
          key={index}
          onClick={() => { this.props.onDropDownChangedRepairDetails('keyPositionSubstituteVehicle', value); }}
        >
          {value}
        </DropdownItem>
      ))
    );
  }

  isDisableRepairCostsDropdown = () => {
    const { dataRepairDetails } = this.props;
    if (!dataRepairDetails.status) {
      return true;
    }
    if ((dataRepairDetails.status.toLowerCase() === 'done')
    || (dataRepairDetails.status.toLowerCase() === 'booking')) {
      return true;
    }
    return false;
  }

  isDisableStatusDropdown = () => {
    const { dataRepairDetails } = this.props;
    if (!dataRepairDetails.status) {
      return true;
    }
    if ((dataRepairDetails.status.toLowerCase() === 'booking')
    || (dataRepairDetails.status.toLowerCase() === 'done')) {
      return true;
    }
    return false;
  }

  isDisableMachineDropdown = () => {
    const { dataRepairDetails } = this.props;
    if (!dataRepairDetails.status) {
      return true;
    }
    if (dataRepairDetails.status.toLowerCase() !== 'completed') {
      return true;
    }
    return false;
  }

  isDisableKeyDropdown = () => {
    const { dataRepairDetails } = this.props;
    console.log(dataRepairDetails)
    if (!dataRepairDetails.machineName) {
      return true;
    }
    if ((dataRepairDetails.machineName === null)
    || (dataRepairDetails.machineName.toLowerCase() === 'select machine')
    || (dataRepairDetails.status.toLowerCase() === 'done')) {
      return true;
    }
    return false;
  }

  isDisableRepairNotes = () => {
    const { dataRepairDetails } = this.props;
    if (!dataRepairDetails.status) {
      return true;
    }
    if ((dataRepairDetails.status.toLowerCase() === 'completed')
    || (dataRepairDetails.status.toLowerCase() === 'done')) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.keyAvailableList) {
      this.setState({unoccupiedLocks: nextProps.keyAvailableList});
    }
  }

  render = () => {
    const { dataRepairDetails } = this.props;
    const {
      dropdownStatus, dropdownMachine, dropdownKey, dropdownKeySubstitute, dropdownDepartment
    } = this.state;

    this.getFieldsStatus();
    return (
      <Row className="booking-detail">
        <Col md="12">
          <Jumbotron>
            <Row className="mb-3">
              <Col md="12">
                <Row style={{ marginLeft: 0 }}>
                  <h3 className="text__header--sub">
                    Repair Details
                  </h3>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0">
                    Repair costs:
                  </Label>
                  <Col sm={8}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        name="repairCosts"
                        id="repairCosts"
                        onChange={this.props.onInputChangedRepairDetails}
                        value={dataRepairDetails.repairCosts ? dataRepairDetails.repairCosts : ''}
                        // disabled={this.isDisableRepairCostsDropdown()}
                        disabled={this.fieldsStatus.isDisableRepairCosts}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0">
                    Return date:
                  </Label>
                  <Col sm={8}>
                    <DatePicker
                      selected={dataRepairDetails.returnDate ? second2MiliSecondEpoch(dataRepairDetails.returnDate) : new Date()}
                      onChange={(date) => { this.props.onChangeReturnDate(date); }}
                      dateFormat="dd-MM-yyyy"
                      minDate={dataRepairDetails.checkInDate ? second2MiliSecondEpoch(dataRepairDetails.checkInDate) : new Date()}
                      // maxDate={maxDate}
                      customInput={<InputDatePicker />}
                      // showTimeSelect
                      // timeFormat="hh:mm a"
                      // disabled={this.isDisableRepairCostsDropdown()}
                      disabled={this.fieldsStatus.isDisableReturnDate}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0">
                    Status:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownStatus} toggle={this.toggleBookingStatus}>
                      <DropdownToggle caret disabled={this.fieldsStatus.isDisableStatus}>
                        {dataRepairDetails.status ? dataRepairDetails.status : 'Booking'}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderStatusItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>

                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0">
                    Return machine:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownMachine} toggle={this.toggleMachine}>
                      <DropdownToggle caret disabled={this.fieldsStatus.isDisableReturnMachine}>
                        {dataRepairDetails.machineName ? dataRepairDetails.machineName : 'Select machine'}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderMachineItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>

                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info">
                    Key position:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownKey} toggle={this.toggleKey}>
                      <DropdownToggle caret disabled={this.fieldsStatus.isDisableKeyPosition}>
                        {dataRepairDetails.keyPosition ? dataRepairDetails.keyPosition : 'Select key position'}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderKeyItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info">
                    Department:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownDepartment} toggle={this.toggleDepartment}>
                      <DropdownToggle caret disabled={this.fieldsStatus.isDisableDepartment}>
                        {dataRepairDetails.department ? this.departmentChoice[dataRepairDetails.department] : 'Select department'}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderDepartmentItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                {dataRepairDetails.isKeySubstituteVehicle ? (
                  <FormGroup>
                    <Label className="text__title text__title--info" for="exampleText">
                      Repair Notes
                    </Label>
                    <Input
                      style={{ height: '14rem', width: '85%' }}
                      type="textarea"
                      name="repairNotes"
                      id="exampleText"
                      onChange={this.props.onInputChangedRepairDetails}
                      value={dataRepairDetails.repairNotes ? dataRepairDetails.repairNotes : ''}
                      disabled={this.fieldsStatus.isDisableRepairNotes}
                    />
                  </FormGroup>) : ''
                }
              </Col>
              <Col md="6">
                <FormGroup row style={{ marginLeft: 23, height: 30 }}>
                  <Label check className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    <Input
                      type="checkbox"
                      name="isKeySubstituteVehicle"
                      checked={dataRepairDetails.isKeySubstituteVehicle ? dataRepairDetails.isKeySubstituteVehicle : false}
                      disabled={this.fieldsStatus.isDisableCheckboxUseSubstituteVehicle}
                      onChange={this.onChangeCheckboxUseSubstituteVehicle}
                    />
                    Use substitute vehicle
                  </Label>
                </FormGroup>
                {dataRepairDetails.isKeySubstituteVehicle ? (
                  <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    Substitute vehicle key position:
                  </Label>
                  <Col sm={8}>
                    <Dropdown isOpen={dropdownKeySubstitute} toggle={this.toggleKeySubstitute}>
                      <DropdownToggle caret disabled={this.fieldsStatus.isDisableKeyPositionSubstituteVehicle}>
                        {dataRepairDetails.keyPositionSubstituteVehicle ? dataRepairDetails.keyPositionSubstituteVehicle : 'Select key position'}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.renderKeySubstituteItem()}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                ) : ''}

                {dataRepairDetails.isKeySubstituteVehicle ? (
                  <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                  Substitute vehicle plate number:
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="plateNumberSubstituteVehicle"
                      id="plateNumberSubstituteVehicle"
                      onChange={this.props.onInputChangedPlateNumberSubstitute}
                      value={dataRepairDetails.plateNumberSubstituteVehicle ? dataRepairDetails.plateNumberSubstituteVehicle : ''}
                      disabled={this.fieldsStatus.isDisablePlateNumberSubstituteVehicle}
                    />
                  </Col>
                </FormGroup>
                ) : ''}

                {!dataRepairDetails.isKeySubstituteVehicle ? (
                <FormGroup>
                  <Label className="text__title text__title--info" for="exampleText">
                    Repair Notes
                  </Label>
                  <Input
                    style={{ height: '11.2rem' }}
                    type="textarea"
                    name="repairNotes"
                    id="exampleText"
                    onChange={this.props.onInputChangedRepairDetails}
                    value={dataRepairDetails.repairNotes ? dataRepairDetails.repairNotes : ''}
                    disabled={this.fieldsStatus.isDisableRepairNotes}
                  />
                </FormGroup>): ''
                }
              </Col>
            </Row>
          </Jumbotron>
        </Col>
      </Row>
    );
  }
}

RepairDetails.defaultProps = {
};

RepairDetails.propTypes = {
  dataRepairDetails: PropTypes.shape().isRequired,
  loadingPage: PropTypes.func.isRequired,
  onInputChangedRepairDetails: PropTypes.func.isRequired,
  onDropDownChangedRepairDetails: PropTypes.func.isRequired,
  onChangeReturnDate: PropTypes.func.isRequired,
  rootStatus: PropTypes.string.isRequired,
  onInputChangedPlateNumberSubstitute: PropTypes.func.isRequired,
  isUseSubstituteVehicle: PropTypes.func.isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    machines: state.machines,
  };
}

const mapDispatchToProps = {
  loadingPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepairDetails);
