const AppFlowActions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',

  LOADING_COMPLETE: 'LOADING_COMPLETE',
  RELOAD_PAGE_REQUEST: 'RELOAD_PAGE_REQUEST',

  GET_ALL_OCCUPPIED_LOCK_REQUEST: 'GET_ALL_OCCUPPIED_LOCK_REQUEST',
  GET_ALL_OCCUPPIED_LOCK_COMPLETE: 'GET_ALL_OCCUPPIED_LOCK_COMPLETE',

  GET_ALL_MACHINE_REQUEST: 'GET_ALL_MACHINE_REQUEST',
  GET_ALL_MACHINE_COMPLETE: 'GET_ALL_MACHINE_COMPLETE',

  GET_A_MACHINE_REQUEST: 'GET_A_MACHINE_REQUEST',
  GET_A_MACHINE_COMPLETE: 'GET_A_MACHINE_COMPLETE',

  GET_ALL_DATA_REQUEST: 'GET_ALL_DATA_REQUEST',
  GET_ALL_DATA_COMPLETE: 'GET_ALL_DATA_COMPLETE',

  GET_ALL_BOOKING_REQUEST: 'GET_ALL_BOOKING_REQUEST',
  GET_ALL_BOOKING_COMPLETE: 'GET_ALL_BOOKING_COMPLETE',

  GET_A_BOOKING_REQUEST: 'GET_A_BOOKING_REQUEST',
  GET_A_BOOKING_COMPLETE: 'GET_A_BOOKING_COMPLETE',

  UPDATE_BOOKING_REQUEST: 'UPDATE_BOOKING_REQUEST',
  UPDATE_BOOKING_COMPLETE: 'UPDATE_BOOKING_COMPLETE',

  DELETE_BOOKING_REQUEST: 'DELETE_BOOKING_REQUEST',
  DELETE_BOOKING_COMPLETE: 'DELETE_BOOKING_COMPLETE',

  SET_SEARCH_REQUEST: 'SET_SEARCH_REQUEST',
  SET_SEARCH_COMPLETE: 'SET_SEARCH_COMPLETE',

  CREATE_MACHINE_REQUEST: 'CREATE_MACHINE_REQUEST',
  CREATE_MACHINE_COMPLETE: 'CREATE_MACHINE_COMPLETE',

  DELETE_MACHINE_REQUEST: 'DELETE_MACHINE_REQUEST',
  DELETE_MACHINE_COMPLETE: 'DELETE_MACHINE_COMPLETE',

  UPDATE_MACHINE_REQUEST: 'UPDATE_MACHINE_REQUEST',
  UPDATE_MACHINE_COMPLETE: 'UPDATE_MACHINE_COMPLETE',

  GET_ALL_USER_REQUEST: 'GET_ALL_USER_REQUEST',
  GET_ALL_USER_COMPLETE: 'GET_ALL_USER_COMPLETE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_COMPLETE: 'CREATE_USER_COMPLETE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_COMPLETE: 'DELETE_USER_COMPLETE',

  GET_A_USER_REQUEST: 'GET_A_USER_REQUEST',
  GET_A_USER_COMPLETE: 'GET_A_USER_COMPLETE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_COMPLETE: 'UPDATE_USER_COMPLETE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',

  START_CONFERENCE: 'START_CONFERENCE',
  TRACK_ADDED: 'TRACK_ADDED',
  TRACK_REMOVED: 'TRACK_REMOVED:',
  STOP_CONFERENCE: 'STOP_CONFERENCE',

  GET_ALL_QR_CODE_REQUEST: 'GET_ALL_QR_CODE_REQUEST',
  GET_ALL_QR_CODE_COMPLETE: 'GET_ALL_QR_CODE_COMPLETE',

  CREATE_QR_CODE_REQUEST: 'CREATE_QR_CODE_REQUEST',
  CREATE_QR_CODE_COMPLETE: 'CREATE_QR_CODE_COMPLETE',

  DELETE_QR_CODE_REQUEST: 'DELETE_QR_CODE_REQUEST',
  DELETE_QR_CODE_COMPLETE: 'DELETE_QR_CODE_COMPLETE',
};

export default AppFlowActions;
