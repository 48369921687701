import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';
import CreateUserInfo from '../../components/CreateUserInfo';

import { createUserRequest } from '../../Redux/Actions/users';
import loadingPage from '../../Redux/Actions/loading';

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleCreateUser = (data, callback) => {
    const { createUserRequest, loadingPage, history } = this.props;

    try {
      loadingPage(true);
      createUserRequest(data, () => {
        callback();
        history.push('/admin/users');
      });
    } catch (error) {
      loadingPage(false)
    }
  }

  onClickBack = (e) => {
    e.preventDefault();
    const { currentStep } = this.state;
    if (currentStep === 3) {
      this.setState({
        addedInsurances: [],
        addedInsurancesInsideBorder: {},
        addedOptionRents: [],
        totalPrice: 0,
        numberOfExtraDriver: 0,
      });
    }
    this.setState({ currentStep: currentStep - 1 });
  }

  render = () => {
    return (
      <div className="create-booking">
        <main className="main">
          <Container fluid>
            <Row className="booking-name">
              <Link to="/admin/users">
                <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
              </Link>
              <div className="create-booking__header">
                <h2 className="text__header">
                  NEW USER
                </h2>
              </div>
            </Row>
            <CreateUserInfo handleCreateUser={this.handleCreateUser} />
          </Container>
        </main>
      </div>
    );
  }
}

CreateUser.propTypes = {
  createUserRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  createUserRequest,
  loadingPage,
};

export default connect(null, mapDispatchToProps)(CreateUser);
