import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron, Col, Row,
  Label, Button,
  Input, FormGroup
} from 'reactstrap';

import { checkNull } from '../../utils';

const DEFAULT_DATA = {
  company: '',
  location: '',
};

const CreateQRCodeInfo = ({ handleCreateQRCode }) => {
  const [qrCodeBody, setQRCodeBody] = useState({ ...DEFAULT_DATA });
  const [invalidField, setInvalidField] = useState('');

  const onChange = (e) => {
    qrCodeBody[e.target.name] = e.target.value;
    setQRCodeBody({ ...qrCodeBody });
  };

  const onCreateClick = (e) => {
    const { company, location } = qrCodeBody;
    const dataCheckNull = [
      { field: 'company', value: company },
      { field: 'location', value: location },
    ];

    const _invalidField = checkNull(dataCheckNull);

    if (_invalidField) {
      setInvalidField(_invalidField);
      return;
    }

    e.preventDefault();

    const data = {
      company_name: company,
      location,
    };

    handleCreateQRCode(data, () => {
      setQRCodeBody({ ...DEFAULT_DATA });
    });
  };

  return (
    <Jumbotron className="create-booking__jumbotron --child">
      <Col md={{ offset: 2, size: 8 }}>
        <form
          className="create-booking__form mt-4 form-horizontal"
          onSubmit={onCreateClick}
        >
          <Row>
            <Col xs="12">
              <h2 className="text-center mb-4">
                <strong>
                Towing Company Information
                </strong>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: '10', offset: '1' }} xs="12">
              <FormGroup className="ml-0">
                <Label className="text__title text__title--info mb-1">
                  Company name:
                </Label>
                <Input
                  className={invalidField === 'company' ? 'is-invalid' : ''}
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  value={qrCodeBody.company}
                  onChange={onChange}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col md={{ size: '10', offset: '1' }} xs="12">
              <FormGroup className="ml-0">
                <Label className="text__title text__title--info mb-1">
                  Location:
                </Label>
                <Input
                  className={invalidField === 'location' ? 'is-invalid' : ''}
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={qrCodeBody.location}
                  onChange={onChange}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5 mb-4 justify-content-center">
            <Button
              className="create-booking__btn create-booking__btn-finish"
              onClick={onCreateClick}
            >
              Create Towing Company
            </Button>
          </Row>
        </form>
      </Col>
    </Jumbotron>
  );
};

CreateQRCodeInfo.propTypes = {
  handleCreateQRCode: PropTypes.func,
};

export default CreateQRCodeInfo;
