import { fetchClientWithToken, fetchClient } from './fetch-client';
import {
  MACHINE_URL, BOOKING_URL, LOGIN_URL,
  ALL_OCCUPPIED_LOCK_URL, CHECKIN, USER_URL,
  QR_CODE_URL,
} from '../../config';
import auth from './auth';
import { memorizeMachines } from '../../utils/memorize';

function fetchWithToken(url, method, body = undefined) {
  try {
    const options = {
      url,
      method,
      body,
      token: auth.token(),
    };

    return fetchClientWithToken(options);
  } catch (error) {
    console.log(error);

    return null;
  }
}

function fetchWithoutToken(url, method, body = undefined) {
  try {
    const options = {
      url,
      method,
      body,
    };

    return fetchClient(options);
  } catch (error) {
    console.log(error);

    return null;
  }
}

export function fetchLogin(body) {
  return fetchWithoutToken(LOGIN_URL, 'POST', body);
}

export function fetchAllBooking() {
  return fetchWithToken(BOOKING_URL, 'GET');
}

export function fetchABooking(bookingId) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'GET');
}

export function fetchABookingQR(qrCode) {
  return fetchWithToken(`${BOOKING_URL}/QRCode/${qrCode}`, 'GET');
}

export function fetchCreateBooking(body) {
  return fetchWithToken(`${BOOKING_URL}`, 'POST', body);
}

export function fetchUpdateBooking(bookingId, body) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'PATCH', body);
}

export function fetchDeleteBooking(bookingId) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'DELETE');
}

export function fetchAllMachine() {
  return fetchWithToken(MACHINE_URL, 'GET');
}

export function fetchAMachine(machineId) {
  return fetchWithToken(`${MACHINE_URL}/${machineId}`, 'GET');
}

export function fetchCreateMachine(body) {
  return fetchWithToken(`${MACHINE_URL}`, 'POST', body);
}

export function fetchUpdateMachine(serialMachine, body) {
  return fetchWithToken(`${MACHINE_URL}/${serialMachine}`, 'PATCH', body);
}

export function fetchAllOccuppiedLock(serialMachine, dateFilter) {
  const url = `${ALL_OCCUPPIED_LOCK_URL}serialMachine=${serialMachine}&dateFilter=${dateFilter}`;
  return fetchWithToken(url, 'GET');
}

export function fetchCheckin(qrCode) {
  return fetchWithoutToken(`${CHECKIN}/${qrCode}`, 'PATCH');
}

export const fetchAll = async () => {
  try {
    const allBookingResult = await fetchAllBooking();
    const allMachineResult = await fetchAllMachine();
    // const allOccuppiedLockResult = await fetchAllOccuppiedLock();

    const bookings = allBookingResult != null ? allBookingResult : [];
    const machines = allMachineResult != null ? allMachineResult : [];
    // const occuppiedLocks = allOccuppiedLockResult != null ? allOccuppiedLockResult : [];

    return {
      bookings,
      machines: memorizeMachines(machines),
      // occuppiedLocks,
    };
  } catch (error) {
    console.log('fetchAll: ', error);

    return null;
  }
};

export function createMachineService(data) {
  return fetchWithToken(MACHINE_URL, 'POST', data);
}

export function deleteMachineService(id) {
  return fetchWithToken(`${MACHINE_URL}/${id}`, 'DELETE');
}

export function updateMachineService(serialMachine, data) {
  return fetchWithToken(`${MACHINE_URL}/${serialMachine}`, 'PATCH', data);
}

export function fetchAllUsers() {
  return fetchWithToken(USER_URL, 'GET');
}

export function createUserService(data) {
  return fetchWithToken(`${USER_URL}/create`, 'POST', data);
}

export function deleteUserService(id) {
  return fetchWithToken(`${USER_URL}/${id}`, 'DELETE');
}

export function getAUserService() {
  return fetchWithToken(`${USER_URL}/me`, 'GET');
}

export function updateUserService(id, data) {
  return fetchWithToken(`${USER_URL}/${id}`, 'PUT', data);
}

export function deleteBookingService(bookingId) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'DELETE');
}

export function changePasswordService(data) {
  return fetchWithToken(`${USER_URL}/me/change-password`, 'PATCH', data);
}

export function getAllQRCodesService() {
  return fetchWithToken(QR_CODE_URL, 'GET');
}

export function CreateQRCodeService(qrCode) {
  return fetchWithToken(`${QR_CODE_URL}/create`, 'POST', qrCode);
}

export function deleteQRCodeService(qrCodeId) {
  return fetchWithToken(`${QR_CODE_URL}/${qrCodeId}`, 'DELETE');
}

export default fetchAll;
