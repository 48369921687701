import {
  put, call, take, fork,
} from 'redux-saga/effects';
import toastr from 'toastr';
import { isFunction } from 'lodash';

import AppFlowActions from '../../constants';
import {
  getAllQRCodesService,
  CreateQRCodeService,
  deleteQRCodeService,
} from '../Helpers/fetch';
import { memoizeQRCodes } from '../../utils/memorize';

function* getAllQRCodes() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      yield take(AppFlowActions.GET_ALL_QR_CODE_REQUEST);

      const response = yield call(getAllQRCodesService);

      if (response) {
        yield put({ type: AppFlowActions.GET_ALL_QR_CODE_COMPLETE, qrCodes: memoizeQRCodes(response) });
      }

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}

function* createQRCode() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { qrCode, callback } = yield take(AppFlowActions.CREATE_QR_CODE_REQUEST);

      const response = yield call(CreateQRCodeService, qrCode);

      if (response) {
        yield put({ type: AppFlowActions.CREATE_QR_CODE_COMPLETE, qrCode: response });
        if (isFunction(callback)) {
          callback();
        }
        toastr.success('Create Towing Company successful', 'Success');
      }

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}


function* deleteQRCode() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { qrCodeId, callback } = yield take(AppFlowActions.DELETE_QR_CODE_REQUEST);

      const { error, result } = yield call(deleteQRCodeService, qrCodeId);

      if (error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        yield callback();
        toastr.error(error.message, 'Error');
        return;
      }

      if (result === 'successfully') {
        yield put({ type: AppFlowActions.DELETE_QR_CODE_COMPLETE, qrCodeId });
        if (isFunction(callback)) {
          callback();
        }
        toastr.success('Delete Towing Company successful', 'Success');
      }

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}

export default function* qrCodes() {
  yield fork(getAllQRCodes);
  yield fork(createQRCode);
  yield fork(deleteQRCode);
};
