import { isEmpty } from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

const users = (state = initialState.users, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_USER_COMPLETE:
      if (!isEmpty(action.users)) {
        return action.users;
      }
      return state;
    case AppFlowActions.CREATE_USER_COMPLETE: {
      const { user } = action;
      if (!isEmpty(user)) {
        state[user.id] = user;
        return { ...state };
      }
      return state;
    }
    case AppFlowActions.DELETE_USER_COMPLETE: {
      const { id } = action;
      if (id) {
        delete state[id];
        return { ...state };
      }
      return state;
    }
    case AppFlowActions.UPDATE_USER_COMPLETE: {
      const { id, user } = action;
      if (!isEmpty(user) && id) {
        state[id] = user;
        return { ...state };
      }
      return state;
    }
    default:
      return state;
  }
};

export default users;
