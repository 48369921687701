import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron, Col, Row,
  Label, Button, DropdownItem,
  Input, FormGroup, Dropdown, 
  DropdownToggle, DropdownMenu
} from 'reactstrap';

import { checkNull } from '../../utils';

class UpdateUserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidField: '',
      password: '',
      department: props.user.department || '',
      dropdownDepartment: '',
    };

    this.departmentChoice = {
      "repair_email": "Body repair", 
      "glass_email": "Glass repair", 
      "cleaning_email": "Cleaning service"
    };
  }

  toggleDropdownDepartment = () => {
    this.setState(prevState => ({ dropdownDepartment: !prevState.dropdownDepartment }));
  }

  onChangeReturnDepartment = (data) => {
    this.setState({ department: data });
  }

  renderDepartmentItem = () => {
    const keys = Object.keys(this.departmentChoice);
    return (
      keys.map((key, index) => (
        <DropdownItem
          name="department"
          key={index}
          onClick={() => { this.onChangeReturnDepartment(key); }}
        >
          {this.departmentChoice[key]}
        </DropdownItem>
      ))
    );
  }

  onChange = (e) =>
    this.setState({ password: e.target.value, invalidField: '' });

  onUpdateClick = (e) => {
    const { handleUpdateUser } = this.props;
    const { password, department } = this.state;
    
    if (password !== '' || department !== ''){
      e.preventDefault();
      handleUpdateUser({ password, department }, () => this.setState({ password: '', department: '' }));
    }
    else {
      const dataCheckNull = [
        { field: 'password', value: password },
      ]
      const invalidField = checkNull(dataCheckNull);
      this.setState({ invalidField });
    }
  }

  render = () => {
    const { user } = this.props;
    const { invalidField, password, dropdownDepartment, department, } = this.state;

    return (
      <Jumbotron className="create-booking__jumbotron --child">
        <Col md={{ offset: 2, size: 8 }}>
          <form
            className="create-booking__form mt-4 form-horizontal"
            onSubmit={this.onUpdateClick}
          >
            <Row>
              <Col xs="12">
                <h2 className="text-center mb-4">
                  <strong>
                    User Information
                  </strong>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Username:
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    value={user.userName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Password:
                  </Label>
                  <Input
                    className={invalidField === 'password' ? 'is-invalid' : ''}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="w-100 ml-0">
                  <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    Department:
                  </Label>
                  <Dropdown
                    isOpen={dropdownDepartment}
                    toggle={this.toggleDropdownDepartment}
                  >
                    <DropdownToggle
                      className={invalidField === 'department' ? 'is-invalid' : ''}
                      caret
                    >
                      {this.departmentChoice[department] || 'Select department'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.renderDepartmentItem()}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5 mb-4 justify-content-center">
              <Button
                className="create-booking__btn create-booking__btn-finish"
                onClick={this.onUpdateClick}
              >
                Update User
              </Button>
            </Row>
          </form>
        </Col>
      </Jumbotron>
    );
  }
}

UpdateUserInfo.defaultProps = {
  user: {
    userName: '',
  },
}

UpdateUserInfo.propTypes = {
  handleUpdateUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default UpdateUserInfo;
