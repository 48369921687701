import AppFlowActions from '../../constants';

/**
 *
 * @param {*}
 */
export const getAllUserRequest = () => ({ type: AppFlowActions.GET_ALL_USER_REQUEST });

/**
 *
 * @param {object} data
 */
export const createUserRequest = (data, callback) => ({ type: AppFlowActions.CREATE_USER_REQUEST, data, callback });

/**
 *
 * @param {string} id
 */
export const deleteUserRequest = (id, callback) => ({ type: AppFlowActions.DELETE_USER_REQUEST, id, callback });

/**
 *
 * @param {*}
 */
export const getAUserRequest = () => ({ type: AppFlowActions.GET_A_USER_REQUEST });

/**
 *
 * @param {string} id
 * @param {object} data
 * @param {function} callback
 */
export const updateUserRequest = (id, userName, data, callback) => ({ type: AppFlowActions.UPDATE_USER_REQUEST, id, userName, data, callback });

/**
 *
 * @param {object} data
 * @param {function} callback
 */
export const changePasswordRequest = (data, callback) => ({ type: AppFlowActions.CHANGE_PASSWORD_REQUEST, data, callback });

