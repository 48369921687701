import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ListQrCode from '../../components/ListQrCode';
import { getAllQRCodesRequest, deleteQRCodeRequest } from '../../Redux/Actions/qrCodes';
import loadingPage from '../../Redux/Actions/loading';

const PortalQrCode = ({
  onGetAllQRCodes,
  qrCodes,
  history,
  onDeleteQRCode,
  onLoadingPage,
}) => {
  useEffect(() => {
    onGetAllQRCodes();
  }, []);

  const onClickAddQRCode = () => history.push('/admin/createqrcode');

  const handleDeleteQRCode = (qrCodeId, callback) => {
    try {
      onLoadingPage(true);
      onDeleteQRCode(qrCodeId, callback);
    } catch (error) {
      onLoadingPage(false);
    }
  };

  return (
    <div className="portal app-body">
      <div className="main">
        <Container fluid>
          <Row>
            <Col xs="6">
              <h2 className="mt-3 mb-3 text__header">
                Towing Company
              </h2>
            </Col>
            <Col xs="6" className="align-self-center text-right">
              <Button
                className="btn"
                onClick={onClickAddQRCode}
              >
                Add Towing Company
              </Button>
            </Col>
          </Row>
          <Row>
            <ListQrCode
              qrCodes={qrCodes}
              handleDeleteQRCode={handleDeleteQRCode}
            />
          </Row>
        </Container>
      </div>
    </div>
  );
};

PortalQrCode.propTypes = {
  qrCodes: PropTypes.objectOf(PropTypes.object),
  onGetAllQRCodes: PropTypes.func,
  history: PropTypes.any,
  onDeleteQRCode: PropTypes.func,
  onLoadingPage: PropTypes.func,
};

const mapStateToProps = state => ({
  qrCodes: state.qrCodes,
});

const mapDispatchToProps = {
  onGetAllQRCodes: getAllQRCodesRequest,
  onDeleteQRCode: deleteQRCodeRequest,
  onLoadingPage: loadingPage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalQrCode));