import {
  put, call, take, fork,
  takeLatest, takeEvery,
} from 'redux-saga/effects';
import toastr from 'toastr';
import { isFunction } from 'lodash';
import { eventChannel } from 'redux-saga';
import EventEmitter from 'events';

import AppFlowActions from '../../constants';
import {
  fetchAllMachine,
  fetchAMachine,
  createMachineService,
  deleteMachineService,
  updateMachineService,
} from '../Helpers/fetch';
import { memorizeMachines } from '../../utils/memorize';
import { trackAdded, trackRemoved } from '../Actions/machine';
import jitsiInstance, { JitsiConferenceEvents } from '../../utils/jitsi';
import jitsiConfig from '../../utils/jitsi/config';
import { RemoteControl } from '../../remoteControlContants';

function fetch(serialMachine) {
  try {
    return fetchAMachine(serialMachine);
  } catch (error) {
    return null;
  }
}

function fetchAll() {
  try {
    return fetchAllMachine();
  } catch (error) {
    return null;
  }
}


function* allMachineListFlow() {
  const INFINITE = true;
  while (INFINITE) {
    yield take(AppFlowActions.GET_ALL_MACHINE_REQUEST);

    const machines = yield call(fetchAll);
    console.log('all machine ', machines);
    if (machines) {
      yield put({ type: AppFlowActions.GET_ALL_MACHINE_COMPLETE, machines: memorizeMachines(machines) });
    }

    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
  }
}

function* aMachineListFlow() {
  const INFINITE = true;
  while (INFINITE) {
    const request = yield take(AppFlowActions.GET_A_MACHINE_REQUEST);
    const { serialMachine } = request;

    const machine = yield call(fetch, serialMachine);

    console.log('a machine ', machine);
    if (machine) {
      yield put({ type: AppFlowActions.GET_A_MACHINE_COMPLETE, machine });
    }

    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
  }
}

function* createMachine() {
  try {
    const INFINITE = true;
    while (INFINITE) {
      const { data, callback } = yield take(AppFlowActions.CREATE_MACHINE_REQUEST);
      const machine = yield call(createMachineService, data);

      if (machine.error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        return toastr.error(machine.error.message, 'Error');
      }

      if (machine) {
        yield put({ type: AppFlowActions.CREATE_MACHINE_COMPLETE, machine });
        yield callback();
        toastr.success('Create machine successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}

function* deleteMachine() {
  try {
    const INFINITE = true;
    while (INFINITE) {
      const { id, serialMachine, callback } = yield take(AppFlowActions.DELETE_MACHINE_REQUEST);
      const { error, result } = yield call(deleteMachineService, id);

      if (error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        return toastr.error(error.message, 'Error');
      }

      if (result === 'successfully') {
        yield put({ type: AppFlowActions.DELETE_MACHINE_COMPLETE, serialMachine });
        yield callback();
        toastr.success('Delete machine successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}

function* updateMachine() {
  try {
    const INFINITE = true;
    while (INFINITE) {
      const { serialMachine, data, callback } = yield take(AppFlowActions.UPDATE_MACHINE_REQUEST);
      const response = yield call(updateMachineService, serialMachine, data);

      if (response.error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        return toastr.error(response.error.message, 'Error');
      }

      if (response) {
        yield put({ type: AppFlowActions.UPDATE_MACHINE_COMPLETE, machine: response });
        if (isFunction(callback)) {
          yield callback();
        }
        toastr.success('Update machine successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}


function* setConfig(config) {
  yield call(jitsiInstance.initLib, config);
}

function startConferenceSubscriber() {
  const unsubscribe = () => {
    jitsiInstance.disconnect();
  };
  const eventEmitter = new EventEmitter();

  return eventChannel(emit => {
    jitsiInstance.connect(eventEmitter);

    eventEmitter.on(JitsiConferenceEvents.TRACK_ADDED, payload => {
      emit(trackAdded(payload));
    });

    eventEmitter.on(JitsiConferenceEvents.TRACK_REMOVED, payload => {
      emit(trackRemoved(payload));
    });

    return unsubscribe;
  });
}

export function* startConference({ room }) {
  try {
    yield setConfig(jitsiConfig);
    if (jitsiInstance.roomName) {
      jitsiInstance.unload();
    }
    yield call(jitsiInstance.setRoomName, room);
    const channel = yield call(startConferenceSubscriber);

    yield takeEvery(channel, function* s(value) {
      yield put(value);
    });
    yield localStorage.setItem(`isInitStream_${RemoteControl.PROJECT_ID}`, true);
  } catch (error) {
    console.log('startConference error', error);
  }
}

export function* stopConference() {
  try {
    yield call(jitsiInstance.unload);
  } catch (error) {
    console.log('stopConference error', error);
  }
}

export default function* machineFlow() {
  yield fork(allMachineListFlow);
  yield fork(aMachineListFlow);
  yield fork(createMachine);
  yield fork(deleteMachine);
  yield fork(updateMachine);

  yield takeLatest(AppFlowActions.START_CONFERENCE, startConference);
  yield takeLatest(AppFlowActions.STOP_CONFERENCE, stopConference);
}
