import {
  put, call, take, fork,
} from 'redux-saga/effects';
import toastr from 'toastr';
import { isFunction } from 'lodash';

import AppFlowActions from '../../constants';
import {
  fetchAllUsers,
  createUserService,
  deleteUserService,
  getAUserService,
  updateUserService,
  changePasswordService,
} from '../Helpers/fetch';
import { memorizeUsers } from '../../utils/memorize';

function* getAllUsers() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      yield take(AppFlowActions.GET_ALL_USER_REQUEST);

      const { result } = yield call(fetchAllUsers);

      if (result) {
        yield put({ type: AppFlowActions.GET_ALL_USER_COMPLETE, users: memorizeUsers(result) });
      }

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error, 'Error');
  }
}

function* createNewUser() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { data, callback } = yield take(AppFlowActions.CREATE_USER_REQUEST);

      const user = yield call(createUserService, data);

      if (user.error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        toastr.error(user.error.message, 'Error');
        return;
      }

      if (user) {
        yield put({ type: AppFlowActions.CREATE_USER_COMPLETE, user });
        yield callback();
        toastr.success('Create user successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error.message, 'Error');
  }
}

function* deleteUser() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { id, callback } = yield take(AppFlowActions.DELETE_USER_REQUEST);

      const { error, result } = yield call(deleteUserService, id);

      if (error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        yield callback();
        toastr.error(error.message, 'Error');
        return;
      }

      if (result === 'successfully') {
        yield put({ type: AppFlowActions.DELETE_USER_COMPLETE, id });
        yield callback();
        toastr.success('Delete user successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error.message, 'Error');
  }
}

function* getAUser() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      yield take(AppFlowActions.GET_A_USER_REQUEST);

      const { result, error } = yield call(getAUserService);

      if (error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        toastr.error(error.message, 'Error');
        return;
      }

      if (result) {
        yield put({ type: AppFlowActions.GET_A_USER_COMPLETE, user: result });
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error.message, 'Error');
  }
}

function* updateUser() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { id, userName, data, callback } = yield take(AppFlowActions.UPDATE_USER_REQUEST);

      const response = yield call(updateUserService, userName, data);

      if (response.error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        toastr.error(response.error.message, 'Error');
        return;
      }

      if (response) {
        yield put({ type: AppFlowActions.UPDATE_USER_COMPLETE, id, user: response });
        if (isFunction(callback)) {
          yield callback();
        }
        toastr.success('Update user successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error.message, 'Error');
  }
}


function* changePassword() {
  try {
    const INFINITE = true;

    while (INFINITE) {
      const { data, callback } = yield take(AppFlowActions.CHANGE_PASSWORD_REQUEST);

      const response = yield call(changePasswordService, data);

      if (response.error) {
        yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
        toastr.error(response.error.message, 'Error');
        return;
      }

      if (response) {
        if (isFunction(callback)) {
          yield callback();
        }
        toastr.success('Update password successful', 'Success');
      }
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  } catch (error) {
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    toastr.error(error.message, 'Error');
  }
}

export default function* usersFlow() {
  yield fork(getAllUsers);
  yield fork(createNewUser);
  yield fork(deleteUser);
  yield fork(getAUser);
  yield fork(updateUser);
  yield fork(changePassword);
}
