import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron, Col, Row,
  Label, Button,
  Input, FormGroup, Dropdown, DropdownToggle,
  DropdownMenu, DropdownItem,
} from 'reactstrap';
import Select from 'react-select';
import {
  map,
  isEmpty,
  isArray,
  union,
  get,
} from 'lodash';
import toastr from 'toastr';

import { checkNull, checkEmail } from '../../utils';

const STATUS_ITEM = ['Open', 'Close'];
const STATUS_FIRE_SENSOR = ['Alarmed', 'Not alarmed'];
const DATA_MACHINE = {
  serialMachine: '',
  name: '',
  upperDoor: 'Close',
  lowerDoor: 'Close',
  fireSensor: 'Not alamed',
  publicIpAddress: '',
  location: '',
  status: 'Offline',
  permissions: [],
  email: '',
  repairEmail: '',
  glassEmail: '',
  cleaningEmail: '',
};

class UpdateMachineInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidField: '',
      assignUsers: this.initAssignUsers(props),
      publicIpAddress: '',
      location: '',
      toggleUpperDoor: false,
      toggleLowerDoor: false,
      toggleFireSensor: false,
      email: '',
      repairEmail: '',
      glassEmail: '',
      cleaningEmail: '',
    };
  }

  componentDidMount() {
    const { machine: { publicIpAddress, location, email, repairEmail, glassEmail, cleaningEmail} } = this.props;
    this.setState({
      publicIpAddress,
      location,
      assignUsers: this.initAssignUsers(this.props),
      email,
      repairEmail, 
      glassEmail, 
      cleaningEmail,
    });
  }

  onChange = (e) => {
    if (isArray(e)) {
      this.setState({ assignUsers: e, invalidField: '' });
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ invalidField: '' });
  }

  onUpdateClick = (e) => {
    const { handleUpdateMachine, machine } = this.props;
    const { assignUsers } = this.state;
    const { publicIpAddress, location, email, repairEmail, glassEmail, cleaningEmail} = this.state;

    const dataCheckNull = [
      { field: 'email', value: email },
      { field: 'publicIpAddress', value: publicIpAddress },
      { field: 'location', value: location },
      { field: 'repairEmail', value: repairEmail },
      { field: 'glassEmail', value: glassEmail },
      { field: 'cleaningEmail', value: cleaningEmail },
    ];

    const invalidField = checkNull(dataCheckNull);

    if (invalidField) {
      this.setState({ invalidField });
      return;
    }

    if (!checkEmail(email)) {
      toastr.error('Email invalid', 'Error');
      return;
    }

    e.preventDefault();
    let permissions = [];
    const oldPermissions = (machine.permissions || []).map(item => item.userId);
    const newPermissions = assignUsers.map(item => item.value);
    const comparePermissions = union(oldPermissions, newPermissions);

    if (!isEmpty(comparePermissions)) {
      permissions = comparePermissions.map(userId => ({
        user_id: userId,
        permission_detail: newPermissions.includes(userId) ? ['read'] : [],
      }));
    }

    const body = {
      publicIpAddress,
      location,
      email,
      repairEmail, 
      glassEmail, 
      cleaningEmail,
      permissions: [
        ...permissions,
      ],
    };

    handleUpdateMachine(body);
  }

  renderDoorStatusItem = (field, items) => (
    map(items, (value, index) => (
      <DropdownItem
        name={field}
        key={index}
        onClick={this.onClickDoorStatus(field, value)}
      >
        {value}
      </DropdownItem>
    ))
  );

  toggleDoor = field => () => {
    this.setState(prevState => ({ [field]: prevState[field] }));
  }

  onClickDoorStatus = (field, value) => () => console.log({ field, value });

  formatAssignUsers = () => {
    const { users } = this.props;

    if (isEmpty(users)) return [];

    return Object.keys(users).map(id => ({
      value: id,
      label: users[id].userName,
    }));
  }

  initAssignUsers = (props) => {
    const { users, machine: { permissions, id: machineId } } = props;

    if (isEmpty(permissions) || isEmpty(users)) return [];

    return permissions.reduce((result, permission) => {
      const machinePermissions = get(permission, 'permissions.Machine', {});
      if (isEmpty(users[permission.userId]) || isEmpty(machinePermissions[machineId])) return result;

      result.push({
        value: permission.userId,
        label: users[permission.userId].userName,
      });

      return result;
    }, []);
  }

  render = () => {
    const {
      machine: {
        name,
        serialMachine,
        upperDoor,
        lowerDoor,
        fireSensor,
      },
    } = this.props;
    const {
      invalidField,
      assignUsers,
      publicIpAddress,
      location,
      toggleUpperDoor,
      toggleLowerDoor,
      toggleFireSensor,
      email,
      repairEmail,
      glassEmail,
      cleaningEmail,
    } = this.state;

    return (
      <Jumbotron className="create-booking__jumbotron --child">
        <Col md={{ offset: 2, size: 8 }}>
          <form
            className="create-booking__form mt-4 form-horizontal"
            onSubmit={this.onUpdateClick}
          >
            <Row>
              <Col xs="12">
                <h2 className="text-center mb-4">
                  <strong>
                    Machine Information
                  </strong>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Assign user:
                  </Label>
                  <Select
                    options={this.formatAssignUsers()}
                    isMulti
                    onChange={this.onChange}
                    value={assignUsers}
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Serial machine:
                  </Label>
                  <Input
                    type="text"
                    name="serialMachine"
                    value={serialMachine}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Machine name:
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Email:
                  </Label>
                  <Input
                    className={invalidField === 'email' ? 'is-invalid' : ''}
                    type="text"
                    name="email"
                    placeholder="emailExample@gmail.com"
                    value={email}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12" style={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup className="ml-0" style={{ flex: 1, marginRight: 20 }}>
                  <Label className="text__title text__title--info mb-1">
                      Upper door:
                  </Label>
                  <Dropdown isOpen={toggleUpperDoor} toggle={this.toggleDoor('toggleUpperDoor')}>
                    <DropdownToggle caret disabled>
                      {upperDoor}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {this.renderDoorStatusItem('upperDoor', STATUS_ITEM)}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
                <FormGroup className="ml-0" style={{ flex: 1 }}>
                  <Label className="text__title text__title--info mb-1">
                      Lower door:
                  </Label>
                  <Dropdown isOpen={toggleLowerDoor} toggle={this.toggleDoor('toggleLowerDoor')}>
                    <DropdownToggle caret disabled>
                      {lowerDoor}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {this.renderDoorStatusItem('lowerDoor', STATUS_ITEM)}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Fire sensor:
                  </Label>
                  <Dropdown isOpen={toggleFireSensor} toggle={this.toggleDoor('toggleFireSensor')}>
                    <DropdownToggle caret disabled>
                      {fireSensor}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {this.renderDoorStatusItem('fireSensor', STATUS_FIRE_SENSOR)}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Public Ip address:
                  </Label>
                  <Input
                    className={invalidField === 'publicIpAddress' ? 'is-invalid' : ''}
                    type="text"
                    name="publicIpAddress"
                    placeholder="Public Ip address"
                    value={publicIpAddress}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                      Location:
                  </Label>
                  <Input
                    className={invalidField === 'location' ? 'is-invalid' : ''}
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={location}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Repair email:
                  </Label>
                  <Input
                    className={invalidField === 'repairEmail' ? 'is-invalid' : ''}
                    type="text"
                    name="repairEmail"
                    placeholder="emailExample@gmail.com"
                    value={repairEmail}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Glass email:
                  </Label>
                  <Input
                    className={invalidField === 'glassEmail' ? 'is-invalid' : ''}
                    type="text"
                    name="glassEmail"
                    placeholder="emailExample@gmail.com"
                    value={glassEmail}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Cleaning email:
                  </Label>
                  <Input
                    className={invalidField === 'cleaningEmail' ? 'is-invalid' : ''}
                    type="text"
                    name="cleaningEmail"
                    placeholder="emailExample@gmail.com"
                    value={cleaningEmail}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>

            </Row>
            <Row className="mt-5 mb-4 justify-content-center">
              <Button
                className="create-booking__btn create-booking__btn-finish"
                onClick={this.onUpdateClick}
              >
                Update Machine
              </Button>
            </Row>
          </form>
        </Col>
      </Jumbotron>
    );
  }
}

UpdateMachineInfo.defaultProps = {
  machine: DATA_MACHINE,
};

UpdateMachineInfo.propTypes = {
  handleUpdateMachine: PropTypes.func.isRequired,
  users: PropTypes.shape().isRequired,
  machine: PropTypes.shape(),
};

export default UpdateMachineInfo;
