import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron, Col, Row,
  Label, Button,
  Input, FormGroup, Dropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap';

import { checkNull } from '../../utils';

class CreateUserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidField: '',
      username: '',
      password: '',
      department: '',
      dropdownDepartment: false,
    };

    this.departmentChoice = {
      "repair_email": "Body repair", 
      "glass_email": "Glass repair", 
      "cleaning_email": "Cleaning service"
    };
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, invalidField: '' });

  onChangeReturnDepartment = (data) => {
    this.setState({ department: data });
  }

  toggleDropdownDepartment = () => {
    this.setState(prevState => ({ dropdownDepartment: !prevState.dropdownDepartment }));
  }

  renderDepartmentItem = () => {
    const keys = Object.keys(this.departmentChoice);
    return (
      keys.map((key, index) => (
        <DropdownItem
          name="department"
          key={index}
          onClick={() => { this.onChangeReturnDepartment(key); }}
        >
          {this.departmentChoice[key]}
        </DropdownItem>
      ))
    );
  }

  onCreateClick = (e) => {
    const { handleCreateUser } = this.props;
    const { username, password, department } = this.state;

    const dataCheckNull = [
      { field: 'username', value: username },
      { field: 'password', value: password },
    ]

    const invalidField = checkNull(dataCheckNull);

    if (invalidField) {
      this.setState({ invalidField });
      return;
    }

    e.preventDefault();

    const data = {
      username,
      password,
      department,
    }

    console.log(data, "loiu");

    handleCreateUser(data, () =>
      this.setState({ username: '', password: '', department: '' })
    )
  }

  render = () => {
    const {
      invalidField,
      username,
      password,
      dropdownDepartment,
      department,
    } = this.state;

    return (
      <Jumbotron className="create-booking__jumbotron --child">
        <Col md={{ offset: 2, size: 8 }}>
          <form
            className="create-booking__form mt-4 form-horizontal"
            onSubmit={this.onCreateClick}
          >
            <Row>
              <Col xs="12">
                <h2 className="text-center mb-4">
                  <strong>
                    User Information
                  </strong>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Username:
                  </Label>
                  <Input
                    className={invalidField === 'username' ? 'is-invalid' : ''}
                    type="text"
                    name="username"
                    placeholder="User name"
                    value={username}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1">
                    Password:
                  </Label>
                  <Input
                    className={invalidField === 'password' ? 'is-invalid' : ''}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="w-100 ml-0">
                  <Label className="text__title text__title--info mb-0" style={{ width: '16rem' }}>
                    Department:
                  </Label>
                  <Dropdown
                    isOpen={dropdownDepartment}
                    toggle={this.toggleDropdownDepartment}
                  >
                    <DropdownToggle
                      className={invalidField === 'department' ? 'is-invalid' : ''}
                      caret
                    >
                      {this.departmentChoice[department] || 'Select department'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.renderDepartmentItem()}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5 mb-4 justify-content-center">
              <Button
                className="create-booking__btn create-booking__btn-finish"
                onClick={this.onCreateClick}
              >
                Create User
              </Button>
            </Row>
          </form>
        </Col>
      </Jumbotron>
    );
  }
}

CreateUserInfo.propTypes = {
  handleCreateUser: PropTypes.func.isRequired,
};

export default CreateUserInfo;
