import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';
import CreateQRCodeInfo from '../../components/CreateQRCodeInfo';

import { createQRCodeRequest } from '../../Redux/Actions/qrCodes';
import loadingPage from '../../Redux/Actions/loading';

const CreateQRCode = ({ onCreateQRCodeRequest, onLoadingPage, history }) => {
  const handleCreateQRCode = (qrCode, callback) => {
    try {
      onLoadingPage(true);
      onCreateQRCodeRequest(qrCode, () => {
        callback();
        history.push('/admin/qrcode');
      });
    } catch (error) {
      onLoadingPage(false);
    }
  };

  return (
    <div className="create-booking">
      <main className="main">
        <Container fluid>
          <Row className="booking-name">
            <Link to="/admin/users">
              <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
            </Link>
            <div className="create-booking__header">
              <h2 className="text__header">
                New Towing Company
              </h2>
            </div>
          </Row>
          <CreateQRCodeInfo handleCreateQRCode={handleCreateQRCode} />
        </Container>
      </main>
    </div>
  );
};

CreateQRCode.propTypes = {
  onCreateQRCodeRequest: PropTypes.func,
  onLoadingPage: PropTypes.func,
  history: PropTypes.any,
};

const mapDispatchToProps = {
  onCreateQRCodeRequest: createQRCodeRequest,
  onLoadingPage: loadingPage,
};

export default connect(null, mapDispatchToProps)(CreateQRCode);
