import AppFlowActions from '../../constants';

/**
 *
 * @param {*} data
 */
export default function getAllMachineRequest() {
  return ({ type: AppFlowActions.GET_ALL_MACHINE_REQUEST });
}

/**
 *
 * @param {*} data
 */
export function getAMachineRequest(serialMachine) {
  return ({ type: AppFlowActions.GET_A_MACHINE_REQUEST, serialMachine });
}

/**
 *
 * @param {object} data
 */
export const createMachineRequest = (data, callback) => ({
  type: AppFlowActions.CREATE_MACHINE_REQUEST, data, callback,
});

/**
 *
 * @param {string} id
 * @param {string} serialMachine
 * @param {function} callback
 */
export const deleteMachineRequest = (id, serialMachine, callback) => ({
  type: AppFlowActions.DELETE_MACHINE_REQUEST, id, serialMachine, callback,
});

/**
 *
 * @param {string} serialMachine
 * @param {function} callback
 */
export const updateMachineRequest = (serialMachine, data, callback) => ({
  type: AppFlowActions.UPDATE_MACHINE_REQUEST, serialMachine, data, callback,
});

/**
 *
 * @param {string} room
 * @param {string} serialMachine
 */
export const startConference = (room, serialMachine) => ({
  type: AppFlowActions.START_CONFERENCE, room, serialMachine,
});

/**
 *
 * @param {object} track
 */
export const trackAdded = track => ({
  type: AppFlowActions.TRACK_ADDED, track,
});

/**
 *
 * @param {object} track
 */
export const trackRemoved = track => ({
  type: AppFlowActions.TRACK_REMOVED, track,
});

/**
 *
 * @param {string} serialMachine
 */
export const stopConference = serialMachine => ({
  type: AppFlowActions.STOP_CONFERENCE, serialMachine,
});
