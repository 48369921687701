import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Row, Jumbotron,
  FormGroup, Label, Input,
} from 'reactstrap';
import _ from 'lodash';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { formatDisplayDate } from '../../utils';


class BookingInfo extends Component {
  constructor(props) {
    super(props);
    const { dataBookingInfo } = this.props;
    const dataBookingInfoState = _.cloneDeep(dataBookingInfo);
    this.state = {
      dataBookingInfoState,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataBookingInfo } = nextProps;
    const { defaultDataBookingInfoState } = prevState;

    if (dataBookingInfo && dataBookingInfo !== defaultDataBookingInfoState) {
      return {
        defaultDataBookingInfoState: dataBookingInfo,
        dataBookingInfoState: dataBookingInfo,
      };
    }

    return null;
  }

  isDisableDamageDescriptions = () => {
    const { rootStatus } = this.props;
    if (!rootStatus) {
      return true;
    }
    if (rootStatus.toLowerCase() !== 'checkedin') {
      return true;
    }
    return false;
  }

  render = () => {
    const { dataBookingInfo } = this.props;
    return (
      <Row className="booking-detail">
        <Col md="12">
          <Jumbotron>
            <Row className="mb-3">
              <Col md="12">
                <Row style={{ marginLeft: 0 }}>
                  <h3 className="text__header--sub">
                    Booking Information
                  </h3>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div>
                  <span className="text__title text__title--info">
                    Customer:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.customerName}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Phone number:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.phoneNumber}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Status:
                  </span>
                  <span className="text__content info--content__block capitalize-first-letter">
                    {dataBookingInfo.status}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Plate number:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.plateNumber}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Check-out date:
                  </span>
                  <span className="text__content info--content__block">
                    {formatDisplayDate(dataBookingInfo.checkoutDate)}
                  </span>
                </div>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="text__title text__title--info" for="exampleText">Damage Descriptions</Label>
                  <Input
                    style={{ height: '7rem' }}
                    type="textarea"
                    name="damageDescription"
                    id="exampleText"
                    onChange={this.props.onChangedBookingInfo}
                    value={dataBookingInfo.damageDescription ? dataBookingInfo.damageDescription : ''}
                    disabled={this.isDisableDamageDescriptions()}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Jumbotron>
        </Col>
      </Row>
    );
  }
}

BookingInfo.defaultProps = {};

BookingInfo.propTypes = {
  dataBookingInfo: PropTypes.shape().isRequired,
  onChangedBookingInfo: PropTypes.func.isRequired,
  rootStatus: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(BookingInfo);
