import { isEmpty } from 'lodash';

export const memorizeUsers = data => {
  if (isEmpty(data)) return {};
  return data.reduce((result, item) => {
    result[item.id] = item;
    return result;
  }, {});
};

export const memorizeMachines = data => {
  if (isEmpty(data)) return {};

  return data.reduce((result, item) => {
    result[item.serialMachine] = item;
    return result;
  }, {});
};

export const memoizeQRCodes = data => {
  if (isEmpty(data)) return {};

  return data.reduce((result, item) => {
    result[item.id] = item;
    return result;
  }, {});
};