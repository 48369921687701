import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import toastr from 'toastr';
import {
  Container, Col, Row,
  Jumbotron, Button,
} from 'reactstrap';
import loadingPage from '../../Redux/Actions/loading';
import { getAllBookingRequest } from '../../Redux/Actions/booking';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import BookingInfo from '../../components/BookingDetail/BookingInfo';
import RepairDetails from '../../components/BookingDetail/RepairDetails';
import ModalSlider from '../../components/ModalSlider';
import { fetchABooking, fetchUpdateBooking, fetchCheckin, fetchAllOccuppiedLock } from '../../Redux/Helpers/fetch';
import { checkNull, date2MiliSecondEpoch, date2UtcSecondEpoch } from '../../utils';

class BookingDetail extends Component {
  constructor() {
    super();

    this.state = {
      dataDetail: {},
      keyAvailableList: [],
      rootStatus: '',
      currentStatus: '',
    };

    this.prevStateSubstituteVehicleInfo = {};

    this.refSlider = React.createRef();
  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    this.props.loadingPage(true);

    this.fetchDataDetail().then((response) => {
      try {
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }

        if (response.serialMachine && response.machineName) {
          fetchAllOccuppiedLock(response.serialMachine, date2UtcSecondEpoch(new Date())).then(occupiedLocks => {
            const keyAvailableList = this.generateUnoccupiedLocks(occupiedLocks);
            this.setState({
              dataDetail: response,
              rootStatus: response.status,
              currentStatus: response.status,
              keyAvailableList
            });
          });
        } else {
          this.setState({
            dataDetail: response,
            rootStatus: response.status,
            currentStatus: response.status,
          });
        }

        return response;
      } catch (error) {
        console.log('fetchDataDetail', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  generateUnoccupiedLocks = (occupiedLocks) => {
    const unoccupiedLocks = Array.from({ length: 50 }, (v, k) => k + 1);
    _.remove(unoccupiedLocks, n => occupiedLocks.indexOf(n) > -1);

    return unoccupiedLocks;
  }

  fetchDataDetail = async () => {
    const { match } = this.props;
    const { bookingId } = match.params;

    const result = await fetchABooking(bookingId);
    return result;
  }

  fetchUpdateBooking = async (bookingId, data) => {
    const result = await fetchUpdateBooking(bookingId, data);
    return result;
  }

  fetchCheckin = async (qrCode) => {
    const result = await fetchCheckin(qrCode);
    return result;
  }

  updateSeparateData = (name, data) => {
    this.setState({ [name]: data });
  }

  onClickUpdate = () => {
    const { dataDetail, rootStatus } = this.state;
    const { match } = this.props;
    const { bookingId } = match.params;
    const returnDate = dataDetail.returnDate ? dataDetail.returnDate : date2UtcSecondEpoch(new Date());

    let data = {};

    if (rootStatus.toLowerCase() === 'completed') {
      if (!dataDetail.repairCosts || dataDetail.repairCosts === null) {
        toastr.error('Please enter repair costs!', 'Error');
        return;
      }

      if (parseFloat(dataDetail.repairCosts) < 0) {
        toastr.error('Please enter a repair cost greater equal than zero!', 'Error');
        return;
      }

      if (!dataDetail.machineName || dataDetail.machineName === 'Select machine') {
        toastr.error('Please choose machine!', 'Error');
        return;
      }

      if (!dataDetail.keyPosition || dataDetail.keyPosition === 'Select key position') {
        toastr.error('Please choose key position!', 'Error');
        return;
      }

      data = {
        repairCosts: dataDetail.repairCosts,
        damageDescription: dataDetail.damageDescription,
        returnDate,
        repairNotes: dataDetail.repairNotes,
        keyPosition: dataDetail.keyPosition,
        machineName: dataDetail.machineName,
        serialMachine: dataDetail.serialMachine,
        status: dataDetail.status.toLowerCase(),
        department: dataDetail.department,
      };
    } else if (rootStatus.toLowerCase() === 'checkedin') {
      if (dataDetail.repairCosts && parseFloat(dataDetail.repairCosts) < 0) {
        toastr.error('Please enter a repair cost greater equal than zero!', 'Error');
        return;
      }

      data = {
        repairCosts: dataDetail.repairCosts,
        damageDescription: dataDetail.damageDescription,
        returnDate,
        keyPosition: dataDetail.keyPosition,
        serialMachine: dataDetail.serialMachine,
        repairNotes: dataDetail.repairNotes,
        status: dataDetail.status.toLowerCase(),
        department: dataDetail.department,
      };
    } else if (dataDetail.status.toLowerCase() === 'booking') {
      if (dataDetail.isKeySubstituteVehicle) {
        if (!dataDetail.machineName || dataDetail.machineName === 'Select machine') {
          toastr.error('Please choose machine!', 'Error');
          return;
        }

        if (!dataDetail.keyPositionSubstituteVehicle || dataDetail.keyPositionSubstituteVehicle === 'Select key position') {
          toastr.error('Please choose key position for substitute vehicle!', 'Error');
          return;
        }

        if (!dataDetail.plateNumberSubstituteVehicle || dataDetail.plateNumberSubstituteVehicle.trim() === '') {
          toastr.error('Please type substitute vehicle plate number!', 'Error');
          return;
        }
      }

      data = {
        repairNotes: dataDetail.repairNotes,
        isKeySubstituteVehicle: dataDetail.isKeySubstituteVehicle ? dataDetail.isKeySubstituteVehicle : false,
        serialMachine: dataDetail.serialMachine,
        keyPositionSubstituteVehicle: dataDetail.keyPositionSubstituteVehicle,
        plateNumberSubstituteVehicle: dataDetail.plateNumberSubstituteVehicle,
      };
    }

    this.props.loadingPage(true);
    this.fetchUpdateBooking(bookingId, data).then((response) => {
      try {
        this.setState({ currentStatus: dataDetail.status });
        console.log('fetchUpdateBooking response ', response);
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        toastr.success('Update booking successful', 'Success');
        this.props.getAllBookingRequest();
        return response;
      } catch (error) {
        console.log('fetchUpdateBooking', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  onClickSkipCheckin = () => {
    const { dataDetail } = this.state;
    this.props.loadingPage(true);
    this.fetchCheckin(dataDetail.QRCode).then((response) => {
      try {
        console.log('fetchCheckin response ', response);
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        toastr.success('Checkin booking successful', 'Success');
        this.props.getAllBookingRequest();
        return response;
      } catch (error) {
        console.log('fetchCheckin', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  onChangeReturnDate = (date) => {
    const { dataDetail } = this.state;
    const clonedDataDetail = _.cloneDeep(dataDetail);
    clonedDataDetail.returnDate = date2UtcSecondEpoch(date);
    clonedDataDetail.keyPosition = 'Select key position';

    this.setState({ dataDetail: clonedDataDetail });
  }

  onInputChangedRepairDetails = (event) => {
    const { dataDetail } = this.state;
    const { name, value } = event.target;
    const clonedDataDetail = _.cloneDeep(dataDetail);
    clonedDataDetail[name] = value;

    this.setState({ dataDetail: clonedDataDetail });
  }

  onInputChangedPlateNumberSubstitute = (event) => {
    const { dataDetail } = this.state;
    const { name, value } = event.target;
    const clonedDataDetail = _.cloneDeep(dataDetail);
    clonedDataDetail[name] = value;

    this.setState({ dataDetail: clonedDataDetail });
  }

  isUseSubstituteVehicle = (isUse) => {
    const { dataDetail } = this.state;
    const clonedDataDetail = _.cloneDeep(dataDetail);
    clonedDataDetail.isKeySubstituteVehicle = isUse;
    if (isUse) {
      if (!_.isEmpty(this.prevStateSubstituteVehicleInfo)) {
        const { plateNumberSubstituteVehicle, keyPositionSubstituteVehicle } = this.prevStateSubstituteVehicleInfo;
        clonedDataDetail.plateNumberSubstituteVehicle = plateNumberSubstituteVehicle;
        clonedDataDetail.keyPositionSubstituteVehicle = keyPositionSubstituteVehicle;
      }
      // if (clonedDataDetail.serialMachine && clonedDataDetail.machineName) {
      //   fetchAllOccuppiedLock(clonedDataDetail.serialMachine, date2UtcSecondEpoch(new Date())).then(occupiedLocks => {
      //     const keyAvailableList = this.generateUnoccupiedLocks(occupiedLocks);
      //     this.setState({
      //       keyAvailableList
      //     });
      //   });
      // }
    } else {
      const { plateNumberSubstituteVehicle, keyPositionSubstituteVehicle } = clonedDataDetail;
      this.prevStateSubstituteVehicleInfo = { plateNumberSubstituteVehicle, keyPositionSubstituteVehicle };
      clonedDataDetail.plateNumberSubstituteVehicle = '';
      clonedDataDetail.keyPositionSubstituteVehicle = 'Select key position';
    }

    this.setState({ dataDetail: clonedDataDetail });
  }

  onDropDownChangedRepairDetails = (name, value) => {
    const { dataDetail } = this.state;
    const clonedDataDetail = _.cloneDeep(dataDetail);

    if (name === 'machine') {
      clonedDataDetail.machineName = value.name;
      clonedDataDetail.serialMachine = value.serialMachine;
      clonedDataDetail.keyPosition = 'Select key position';
      clonedDataDetail.keyPositionSubstituteVehicle = 'Select key position';
      if (clonedDataDetail.serialMachine && clonedDataDetail.machineName) {
        fetchAllOccuppiedLock(clonedDataDetail.serialMachine, date2UtcSecondEpoch(new Date())).then(occupiedLocks => {
          const keyAvailableList = this.generateUnoccupiedLocks(occupiedLocks);
          this.setState({
            keyAvailableList
          });
        });
      }
    } else if (name === 'status') {
      if (value.toLowerCase() === 'checkedin') {
        clonedDataDetail.status = 'CheckedIn';
        clonedDataDetail.keyPosition = null;
      } else {
        clonedDataDetail[name] = value.toLowerCase();
      }
    } else {
      clonedDataDetail[name] = value;
    }
    this.setState({ dataDetail: clonedDataDetail });
  }

  getTabActiveGoBack = status => {
    if (status) {
      status = status.toLowerCase()
    }
    const statusGoBack = {
      'booking': '1',
      'checkedin': '2',
      'completed': '3',
      'done': '4',
      default: '1'
    }
    return statusGoBack[status] || statusGoBack.default;
  }

  render = () => {
    const { dataDetail, rootStatus, keyAvailableList, currentStatus } = this.state;
    if (!dataDetail) {
      return null;
    }

    let isHiddenUpdateButton = true;
    let isHiddenSkipCheckinButton = true;
    if (rootStatus) {
      isHiddenUpdateButton = rootStatus.toLowerCase() === 'done';
      isHiddenSkipCheckinButton = rootStatus.toLowerCase() !== 'booking';
    }


    const currentActiveTab = this.getTabActiveGoBack(currentStatus);

    return (
      <div className="portal">
        <main className="main">
          <Container fluid>
            <ModalSlider ref={this.refSlider} title="title" />
            <Row className="booking-name">
              <Col xs="6" className="d-flex">
                {
                  !this.props.location.state
                    ? (
                      <Link to={{
                        pathname: '/admin/bookings',
                        state: {
                          activeTab: currentActiveTab,
                        },
                      }}
                      >
                        <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                      </Link>
                    )
                    : !this.props.location.state.isOptions
                      ? (
                        <Link to={this.props.location.state.oldPathname}>
                          <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                        </Link>
                      )
                      : (
                        <Link to={{
                          pathname: this.props.location.state.oldPathname,
                          state: { activeTab: '2' },
                        }}
                        >
                          <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                        </Link>
                      )
                }
                <h2 className="mb-3 text__header">
                  BOOKING
                </h2>
                <h3>
                  {
                    dataDetail
                      ? dataDetail.index
                      : ''
                  }
                </h3>
              </Col>
              <Col xs="6" className="align-self-center text-right">
                <Button
                  className="btn mr-3"
                  onClick={this.onClickSkipCheckin}
                  hidden={isHiddenSkipCheckinButton}
                >
                  {/* Skip Checkin */}
                  <Link to={{
                    pathname: '/admin/bookings',
                    state: {
                      activeTab: '2',
                    },
                  }} style={{color: "white"}}>Skip Checkin</Link>
                </Button>
                <Button
                  className="btn"
                  onClick={this.onClickUpdate}
                  hidden={isHiddenUpdateButton}
                >
                  Update
                </Button>
              </Col>
            </Row>
            <BookingInfo
              dataBookingInfo={dataDetail}
              rootStatus={rootStatus}
              onChangedBookingInfo={this.onInputChangedRepairDetails}

            />
            <RepairDetails
              dataRepairDetails={dataDetail}
              rootStatus={rootStatus}
              onInputChangedRepairDetails={this.onInputChangedRepairDetails}
              onInputChangedPlateNumberSubstitute={this.onInputChangedPlateNumberSubstitute}
              isUseSubstituteVehicle={this.isUseSubstituteVehicle}
              onDropDownChangedRepairDetails={this.onDropDownChangedRepairDetails}
              onChangeReturnDate={this.onChangeReturnDate}
              keyAvailableList={keyAvailableList}
            // fetchKeyStatus={this.fetchKeyStatus}
            // resetAssign={this.resetAssign}
            // setEditingStatus={this.setEditingStatus}
            // isNotAvailable={isNotAvailable}
            // updateSeparateData={this.updateSeparateData}
            // handleChangeBookingTime={this.handleChangeBookingTime}
            />
          </Container>
        </main>
      </div>
    );
  }
}

BookingDetail.defaultProps = {
  match: {
    params: {
      qrCode: '',
    },
  },
};

BookingDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape(),
  }).isRequired,
  loadingPage: PropTypes.func.isRequired,
  getAllBookingRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  loadingPage,
  getAllBookingRequest,
};

export default connect(null, mapDispatchToProps)(BookingDetail);
