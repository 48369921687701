import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row, Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { assign, forEach } from 'lodash';
import { formatLastConnectDate } from '../../utils';

import KeyVending from '../../components/Machines/KeyVending';

import { deleteMachineRequest } from '../../Redux/Actions/machine';
import loadingPage from '../../Redux/Actions/loading';
import { getAllUserRequest } from '../../Redux/Actions/users';

class PortalMachines extends Component {
  state = {}

  componentDidMount = () => {
    const { machines, getAllUserRequest } = this.props;
    const objectSetState = {};

    const dataTable = this.loadData(machines);

    // if (this.props.location.state) {
    //   const { activeTab } = this.props.location.state;

    //   if (activeTab) {
    //     _.assign(objectSetState, { activeTab });

    //     window.history.replaceState(null, null, window.location.pathname);
    //   }
    // }

    assign(objectSetState, {
      dataMachine: dataTable.dataMachine,
    });

    this.setState(objectSetState);

    getAllUserRequest();
  }

  componentDidUpdate = (prevProps) => {
    const { machines } = this.props;

    if (machines !== prevProps.machines) {
      const dataTable = this.loadData(machines);

      this.setState({
        dataMachine: dataTable.dataMachine,
      });
    }
  }

  loadData = (machines) => {
    const dataMachine = [];
    const keys = Object.keys(machines);

    forEach(keys, (key) => {
      dataMachine.push({
        machineId: machines[key].id,
        machineName: machines[key].name,
        status: machines[key].status,
        lastConnect: machines[key].lastConnect,
        lastConnectStringFormat: formatLastConnectDate(machines[key].lastConnect),
        fireSensor: machines[key].fireSensor,
        lowerDoor: machines[key].lowerDoor,
        upperDoor: machines[key].upperDoor,
        serialMachine: machines[key].serialMachine,
        email: machines[key].email || '',
      });
    });

    return ({ dataMachine });
  }

  onClickCreate = () => {
    this.props.history.push('/admin/createmachine');
  }

  handleDeleteMachine = (id, serialMachine, callback) => {
    const { deleteMachineRequest, loadingPage } = this.props;
    try {
      loadingPage(true);
      deleteMachineRequest(id, serialMachine, callback);
    } catch (error) {
      loadingPage(false);
    }
  }

  render = () => {
    const { user } = this.props;
    const { dataMachine } = this.state;

    let tableKV = <div />;

    tableKV = (
      <KeyVending
        data={dataMachine}
        handleDeleteMachine={this.handleDeleteMachine}
        isSuperAdmin={user.isSuperAdmin}
      />
    );

    return (
      <div className="portal app-body">
        <div className="main">
          <Container fluid>
            <Row>
              <Col xs="6">
                <h2 className="mt-3 mb-3 text__header">
                  MACHINES
                </h2>
              </Col>
              {user.isSuperAdmin && (
                <Col xs="6" className="align-self-center text-right">
                  <Button
                    className="btn"
                    onClick={this.onClickCreate}
                  >
                    Add Machine
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12">
                {tableKV}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

PortalMachines.defaultProps = {
  machines: {},
  // location: {
  //   state: {},
  // },
};

PortalMachines.propTypes = {
  machines: PropTypes.objectOf(PropTypes.object),
  // location: PropTypes.shape({
  //   state: PropTypes.object,
  // }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    machines: state.machines,
    user: state.user,
  };
}

const mapDispatchToProps = {
  deleteMachineRequest,
  loadingPage,
  getAllUserRequest,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalMachines));
