import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container, Jumbotron,
  Col, Row,
  Label, Button,
  Input, FormGroup,
} from 'reactstrap';
import toastr from 'toastr';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import { checkNull } from '../../utils';
import loadingPage from '../../Redux/Actions/loading';
import { changePasswordRequest } from '../../Redux/Actions/users';

const DEFAULT_DATA = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = ({ loadingPage, changePasswordRequest }) => {
  const [data, setData] = useState({...DEFAULT_DATA});
  const [invalidField, setInvalidField] = useState('');

  const onChange = e => {
    data[e.target.name] = e.target.value;

    setData({...data});
    setInvalidField('');
  };

  const onUpdateClick = (e) => {
    const { currentPassword, newPassword, confirmPassword } = data;
    const dataCheckNull = [
      { field: 'currentPassword', value: currentPassword },
      { field: 'newPassword', value: newPassword },
      { field: 'confirmPassword', value: confirmPassword },
    ];

    const _invalidField = checkNull(dataCheckNull);

    if (_invalidField) {
      setInvalidField(_invalidField);
      return;
    }

    if (newPassword !== confirmPassword) {
      toastr.error('Confirm password not match', 'Error');
      return;
    }

    e.preventDefault();

    try {
      loadingPage(true);
      changePasswordRequest({
        old_password: data.currentPassword,
        new_password: data.newPassword,
      }, () => setData({...DEFAULT_DATA}));
    } catch (error) {
      loadingPage(false);
    }
  };

  return (
    <div className="create-booking">
      <main className="main">
        <Container fluid>
          <Row className="booking-name">
            <Link to="/admin/users">
              <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
            </Link>
            <div className="create-booking__header">
              <h2 className="text__header">
                CHANGE PASSWORD
              </h2>
            </div>
          </Row>
          <Jumbotron className="create-booking__jumbotron --child">
            <Col md={{ offset: 2, size: 8 }}>
              <form
                className="create-booking__form mt-4 form-horizontal"
                onSubmit={onUpdateClick}
              >
                <Row>
                  <Col xs="12">
                    <h2 className="text-center mb-4">
                      <strong>
                        User Information
                      </strong>
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: '10', offset: '1' }} xs="12">
                    <FormGroup className="ml-0">
                      <Label className="text__title text__title--info mb-1">
                        Current Password:
                      </Label>
                      <Input
                        className={invalidField === 'currentPassword' ? 'is-invalid' : ''}
                        type="password"
                        name="currentPassword"
                        placeholder="Current Password"
                        value={data.currentPassword}
                        onChange={onChange}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: '10', offset: '1' }} xs="12">
                    <FormGroup className="ml-0">
                      <Label className="text__title text__title--info mb-1">
                        New Password:
                      </Label>
                      <Input
                        className={invalidField === 'newPassword' ? 'is-invalid' : ''}
                        type="password"
                        name="newPassword"
                        placeholder="New Password"
                        value={data.newPassword}
                        onChange={onChange}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={{ size: '10', offset: '1' }} xs="12">
                    <FormGroup className="ml-0">
                      <Label className="text__title text__title--info mb-1">
                        Confirm Password:
                      </Label>
                      <Input
                        className={invalidField === 'confirmPassword' ? 'is-invalid' : ''}
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={data.confirmPassword}
                        onChange={onChange}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-5 mb-4 justify-content-center">
                  <Button
                    className="create-booking__btn create-booking__btn-finish"
                    onClick={onUpdateClick}
                  >
                    Submit
                  </Button>
                </Row>
              </form>
            </Col>
          </Jumbotron>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  loadingPage,
  changePasswordRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);