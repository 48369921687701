import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import CreateMachineInfo from '../../components/CreateMachineInfo';

import loadingPage from '../../Redux/Actions/loading';
import { getAllBookingRequest } from '../../Redux/Actions/booking';
import { createMachineRequest } from '../../Redux/Actions/machine';
import { getAllUserRequest } from '../../Redux/Actions/users';

class CreateMachine extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.props.loadingPage(true);
    this.props.getAllUserRequest();
  }

  handleCreateMachineFinished = (body, callback) => {
    const { createMachineRequest, history } = this.props;
    try {
      this.props.loadingPage(true);
      createMachineRequest(body, () => {
        callback();
        history.push('machines');
      });
    } catch (error) {
      this.props.loadingPage(false);
      return false;
    }
  }

  render = () => {
    const { users } = this.props;

    return (
      <div className="create-booking">
        <main className="main">
          <Container fluid>
            <Row className="booking-name">
              <Link to="/admin/machines">
                <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
              </Link>
              <div className="create-booking__header">
                <h2 className="text__header">
                  NEW MACHINE
                </h2>
              </div>
            </Row>
            <CreateMachineInfo handleCreateMachineFinished={this.handleCreateMachineFinished}  users={users} />
          </Container>
        </main>
      </div>
    );
  }
}

CreateMachine.propTypes = {
  loadingPage: PropTypes.func.isRequired,
  getAllBookingRequest: PropTypes.func.isRequired,
  getAllUserRequest: PropTypes.func.isRequired,
  createMachineRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  users: state.users
})

const mapDispatchToProps = {
  loadingPage,
  getAllBookingRequest,
  getAllUserRequest,
  createMachineRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMachine);
