import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';
import UpdateUserInfo from '../../components/UpdateUserInfo';

import { updateUserRequest } from '../../Redux/Actions/users';
import loadingPage from '../../Redux/Actions/loading';

class UpdateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleUpdateUser = (data, callback) => {
    const { updateUserRequest, loadingPage, match: { params: { userId } }, users, history } = this.props;

    try {
      loadingPage(true);
      updateUserRequest(userId, users[userId].userName, data, () => {
        callback();
        history.push('/admin/users');
      });
    } catch (error) {
      loadingPage(false)
    }
  }

  onClickBack = (e) => {
    e.preventDefault();
    const { currentStep } = this.state;
    if (currentStep === 3) {
      this.setState({
        addedInsurances: [],
        addedInsurancesInsideBorder: {},
        addedOptionRents: [],
        totalPrice: 0,
        numberOfExtraDriver: 0,
      });
    }
    this.setState({ currentStep: currentStep - 1 });
  }

  render = () => {
    const { users, match: { params: { userId } } } = this.props;

    return (
      <div className="create-booking">
        <main className="main">
          <Container fluid>
            <Row className="booking-name">
              <Link to="/admin/users">
                <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
              </Link>
              <div className="create-booking__header">
                <h2 className="text__header">
                  UPDATE USER
                </h2>
              </div>
            </Row>
            <UpdateUserInfo handleUpdateUser={this.handleUpdateUser} user={users[userId]} />
          </Container>
        </main>
      </div>
    );
  }
}

UpdateUser.propTypes = {
  updateUserRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  users: state.users,
})

const mapDispatchToProps = {
  updateUserRequest,
  loadingPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
