import React, { Component } from 'react';

class Footer extends Component {
  render = () => (
    <footer className="app-footer">
      <span>
        <a href="https://www.incar.fi" target="_blank" rel="noopener noreferrer">
          Incar
        </a>
        {' '}
        {/* &copy; 2010 */}
      </span>
    </footer>
  )
}

export default Footer;
