import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { forEach } from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

import setSearchRequest from '../../Redux/Actions/search';
import { generateList, addSearchValues } from '../../utils';

import DeleteModal from '../DeleteModal';

library.add(faCircle, faSearch);

class KeyVending extends Component {
  state = {
    search: '',
    serialMachineDelete: null,
    machineIdDelete: null,
    isDeleting: false,
  }

  onChangeSearch = (e) => {
    const { value } = e.target;

    this.setState({
      search: value,
    });

    if (this.searchTimer !== null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.props.setSearchRequest({ bookingSearchString: value });
    }, 500);
  }

  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  statusFormater = (cell) => {
    const greenCircle = (
      <div className="status">
        <FontAwesomeIcon icon="circle" className="status-circle --green" />
        <div className="status--text">
          Online
        </div>
      </div>
    );

    const grayCircle = (
      <div className="status">
        <FontAwesomeIcon icon="circle" className="status-circle --gray" />
        <div className="status--text">
          Offline
        </div>
      </div>
    );

    if (!cell) return grayCircle;

    return cell.toLowerCase() === 'offline' ? grayCircle : greenCircle;
  }

  renderPageList = () => {
    const { data } = this.props;

    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    return listPage;
  }

  renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => this.onToggleDropDown(props.toggleDropDown)}
    />
  )

  onOpenDeleteModal = (id, serial) => (e) => {
    e.stopPropagation();
    this.setState({ machineIdDelete: id, serialMachineDelete: serial });
  }

  onClickUpdateAction = machineId => (e) => {
    e.stopPropagation();
    this.props.history.push(`/admin/updatemachine/${machineId}`);
  }

  renderActions = (cell, row) => {
    return (
      <Fragment>
        <Button
          onClick={this.onClickUpdateAction(row.serialMachine)}
          className="mr-2"
        >
          Edit
        </Button>
        <Button
          color="danger"
          onClick={this.onOpenDeleteModal(row.machineId ,row.serialMachine)}
        >
          Delete
        </Button>
      </Fragment>

    )
  }

  onDeleteMachine = () => {
    const { handleDeleteMachine } = this.props;
    const { machineIdDelete, serialMachineDelete } = this.state;

    this.setState({ isDeleting: true });

    handleDeleteMachine(machineIdDelete, serialMachineDelete, () => {
      this.setState({
       machineIdDelete: null,
        serialMachineDelete: null,
        isDeleting: false,
      })
    });
  }

  onCloseDeleteModal = () =>
    this.setState({
      machineIdDelete: null,
      serialMachineDelete: null,
    });

  render = () => {
    const { data, history, isSuperAdmin } = this.props;
    const { search, machineIdDelete, isDeleting } = this.state;
    const dataFilter = generateList(data, search);

    if (data) {
      forEach(data, (value) => {
        addSearchValues(value, value,
          ['machineId', 'machineName', 'status']);
      });
    }

    return (
      <div className="machines">
        <InputGroup className="form-group form-group-sm react-bs-table-search-form
        input-group input-group-sm input-search__remove-border"
        >
          <Input
            type="text"
            id="search"
            placeholder="Search"
            onChange={this.onChangeSearch}
            defaultValue={search}
          />
          <InputGroupAddon className="input-group-addon input-search__overlap" addonType="prepend">
            <FontAwesomeIcon icon="search" />
          </InputGroupAddon>
        </InputGroup>
        <BootstrapTable
          data={dataFilter}
          options={{
            sizePerPageList: this.renderPageList(),
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            onRowClick(row) {
              history.push(`../admin/machines/${row.serialMachine}`);
            },
          }}
          styleRow={this.styleRow}
          pagination
          striped
          multiColumnSort={3}
          className="cursor-pointer__table"
          hover
        >
          <TableHeaderColumn
            isKey
            dataField="machineId"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="machineName"
            dataAlign="center"
            dataSort
            width="200"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            dataAlign="center"
            dataSort
            width="200"
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataAlign="center"
            dataFormat={this.statusFormater}
            dataSort
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="lastConnectStringFormat"
            dataAlign="center"
          >
            Last Connect
          </TableHeaderColumn>
          {isSuperAdmin && (
            <TableHeaderColumn
              dataAlign="center"
              dataFormat={this.renderActions}
              width="300"
            >
              Actions
            </TableHeaderColumn>
          )}
        </BootstrapTable>
        <DeleteModal
          isOpen={!!machineIdDelete}
          onDelete={this.onDeleteMachine}
          onClose={this.onCloseDeleteModal}
          title="Delete Machine"
          isDeleting={isDeleting}
          submitText="Delete machine"
          description="Are you sure delete machine?"
        />
      </div>
    );
  }
}

KeyVending.defaultProps = {
  data: [],
};

KeyVending.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setSearchRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleDeleteMachine: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool,
};

const mapDispatchToProps = {
  setSearchRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(KeyVending));
